import styled from "styled-components";
import SocialsButton from "../SocialButtons";

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  padding: 30px;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 80%;
  z-index: 100;
`;

const Logo = styled.div`
  font-family: "upheavtt";
  color: white;
  font-size: 40px;
`;

const Header = () => {
  return (
    <Wrapper>
      <Logo>MVPD</Logo>
      <SocialsButton />
    </Wrapper>
  );
};

export default Header;
