import styled from "styled-components";
import SocialsButton from "../SocialButtons";

const Wrapper = styled.div`
  display: flex;
  padding: 30px;
  justify-content: center;
  align-items: center;
  background: black;
  height: 200px;
  flex-direction: column;
`;

const Logo = styled.div`
  font-family: "upheavtt";
  color: white;
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 20px;
`;

const Footer = () => {
  return (
    <Wrapper>
      <Logo>MVPD</Logo>
      <SocialsButton />
    </Wrapper>
  );
};

export default Footer;
