import Discord from "../../svg/Discord.svg";
import styled from "styled-components";

const JoinDiscordButton = styled.a`
  background: #ffb946;
  padding: 15px;
  border: solid 3px #114853;
  border-radius: 10px;
  text-align: center;
  width: 200px;
  font-size: 18px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Icon = styled.img`
  margin-right: 10px;
`;

const Text = styled.span`
  font-weight: 500;
  color: black;
`;

const JoinDiscordTextButton = () => {
  return (
    <JoinDiscordButton href="https://discord.gg/gMsRQxJxky" target="_blank">
      <Icon src={Discord} />
      <Text>Join our discord</Text>
    </JoinDiscordButton>
  );
};

export default JoinDiscordTextButton;
