import Discord from "../../svg/Discord.svg";
import Twitter from "../../svg/Twitter.svg";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
`;

const Button = styled.a`
  background: white;
  border-radius: 100px;
  text-align: center;
  width: 42px;
  height: 42px;
  border: none;
  cursor: pointer;
  margin: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
`;

const Icon = styled.img`
  width: 60%;
`;

const SocialsButton = () => {
  return (
    <Wrapper>
      <Button href="https://twitter.com/mvpd_nft" target="_blank">
        <Icon src={Twitter} />
      </Button>
      <Button href="https://discord.gg/gMsRQxJxky" target="_blank">
        <Icon src={Discord} />
      </Button>
    </Wrapper>
  );
};

export default SocialsButton;
