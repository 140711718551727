import styled from "styled-components";

const Wrapper = styled.div`
  width: 95%;
  color: white;
  margin-top: 10px;
  font-family: "minecraft";
  letter-spacing: 1px;
`;

const Container = styled.div`
  color: white;
  margin-top: 50px;
  font-family: "minecraft";
  letter-spacing: 1px;
  background: #282741;
  padding: 50px;
  border-radius: 50px;

  @media (max-width: 768px) {
    padding: 15px;
    border-radius: 15px;
  }
`;

const SoldOut = styled.button`
  background: #bdbdbd;
  color: #4f504f;
  padding: 20px 50px;
  font-size: 30px;
  font-weight: 800;
  border-radius: 100px;
  border: none;
  outline: inherit;
  font-family: "minecraft";
`;

const MintInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const MintTitleText = styled.div`
  font-size: 3em;
  font-weight: 800;
  font-family: "Minecraft";
  text-align: center;

  @media (max-width: 768px) {
    font-size: 2em;
  }
`;

const MintSubTitleText = styled.div`
  font-size: 22px;
  margin-bottom: 15px;
  margin-top: 10px;
  opacity: 0.7;
  font-family: "Minecraft";
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

const MintSection = () => {
  return (
    <Wrapper>
      <Container>
        <MintInputContainer>
          <MintTitleText>CLAIM YOUR KOP</MintTitleText>
          <MintSubTitleText>
            Enter how many kops you would like to recruit
          </MintSubTitleText>
          <SoldOut>
            Sold out
          </SoldOut>
        </MintInputContainer>
      </Container>
    </Wrapper>
  );
};

export default MintSection;
