import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "mvpd-4cf7b.firebaseapp.com",
  projectId: "mvpd-4cf7b",
  storageBucket: "mvpd-4cf7b.appspot.com",
  messagingSenderId: "119559115423",
  appId: "1:119559115423:web:d75c128fa243a36c32c8aa",
  measurementId: "G-81DRV7CC61",
};

export default initializeApp(firebaseConfig);
