import Heart from "../../../Heart.png";
import Lemon from "../../../Lemon.png";
import Tomato from "../../../Tomato.png";
import styled from "styled-components";

const ChoiceWrapper = styled.div`
  margin: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ChoiceArtworkContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
`;

const Choice = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(#e740cb, #ee7bb4);
  align-items: center;
  padding: 20px;
  border-radius: 15px;
  max-width: 150px;
  height: 200px;
  width: 25%;

  font-size: 18px;
  font-weight: 800;
  border: 3px solid white;
  cursor: pointer;
  opacity: 0.7;

  :hover {
    opacity: 1;
  }

  transition: 0.1s ease;
`;

const Text = styled.div`
  font-size: 18px;
  font-weight: 800;
  font-family: "Minecraft";
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const ImageContainer = styled.div`
  height: 110px;
  width: 95%;
  max-width: 110px;
  border-radius: 12px;
  margin: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #7c2edb;
`;

const Image = styled.img`
  height: 60%;
`;

const ExposeContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: 800;
  font-family: "Minecraft";
  color: #db43b2;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const ChooseWeapon = ({ weapon, onWeaponClicked }) => {
  return (  
    <ExposeContainer>
      <ChoiceWrapper>
        <Title>Expose scammers</Title>
        <ChoiceArtworkContainer>
          <Choice isSelected={weapon === 0} onClick={() => onWeaponClicked(0)}>
            <Text>Tomato</Text>
            <ImageContainer>
              <Image src={Tomato} />
            </ImageContainer>
          </Choice>
          <Choice isSelected={weapon === 1} onClick={() => onWeaponClicked(1)}>
            <Text>Lemon</Text>
            <ImageContainer>
              <Image src={Lemon} />
            </ImageContainer>
          </Choice>
        </ChoiceArtworkContainer>
      </ChoiceWrapper>
      <ChoiceWrapper>
        <Title>Send love</Title>
        <ChoiceArtworkContainer>
          <Choice isSelected={weapon === 2} onClick={() => onWeaponClicked(2)}>
            <Text>Heart</Text>
            <ImageContainer>
              <Image src={Heart} />
            </ImageContainer>
          </Choice>
        </ChoiceArtworkContainer>
      </ChoiceWrapper>
    </ExposeContainer>
  );
};

export default ChooseWeapon;
