import "./particles/particle.css";

import Footer from "./components/Footer/Footer";
import Header from "./components/Header";
// import IntroSection from "./components/IntroSection";
import JoinCommunityTextButton from "./components/JoinDiscordTextButton";
import Koalas from "./KoalaCrew.png";
import MintSection from "./components/MintSection";
import MoonGIF from "./Gif-img/Moon.gif";
import NFT1 from "./KoalaGrid1.png";
import NFT2 from "./KoalaGrid2.png";
import NFT3 from "./KoalaGrid3.png";
import NFT4 from "./KoalaGrid4.png";
import TM1 from "./TeamMember1.png";
import TM2 from "./TeamMember2.png";
import TM3 from "./TeamMember3.png";
import TM4 from "./TeamMember4.png";
import Twitter from "./svg/Twitter.svg";
import UserManual from "./components/UserManual";
import styled from "styled-components";

const AppWrapper = styled.div``;

const ColoredBG = styled.div`
  background: linear-gradient(#9939fa, white);
  width: 100%;
  height: 130vh;
  position: absolute;
`;

const ParticleContainer = styled.div`
  width: 100%;
  height: 70%;
  overflow: hidden;
  position: absolute;
  z-index: 2;
`;

const SectionsContainer = styled.div`
  width: 90%;
  max-width: 1000px;
  margin: auto;
  position: relative;
  padding-top: 100px;
  z-index: 3;
`;

const Section1 = styled.div`
  border-radius: 20px;
  display: flex;
  justify-content: center;
`;

const Section2 = styled.div`
  background: #9064ec;
  margin-bottom: 50px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 50px 50px 50px;

  @media (max-width: 768px) {
    padding: 10px 15px 30px 15px;
  }
`;

const Section3 = styled.div`
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

// const Section4 = styled.div`
//   background: #11cdf6;
//   margin-bottom: 50px;
//   border-radius: 20px;
//   display: flex;
//   flex-direction: column;
//   padding: 75px;
//   align-items: flex-start;

//   @media (max-width: 768px) {
//     padding: 30px;
//   }
// `;

const KoalasImage = styled.img`
  width: 60%;
  align-self: flex-start;
`;

const MVDPTextBig = styled.h1`
  font-family: "upheavtt";
  font-size: 10em;
  color: white;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 5em;
  }
`;

const MVDPTextFull = styled.h3`
  font-size: 2em;
  font-family: "upheavtt";
  font-weight: 100;
  color: white;
  margin-top: 0;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

const Title = styled.div`
  font-size: 3em;
  font-family: "upheavtt";
  color: #13237b;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 2em;
  }
`;

const ParagraphText = styled.div`
  font-size: 1em;
  margin-bottom: 30px;
`;

const ImageRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ImageColumn = styled.div`
  flex: 50%;
  max-width: 50%;
  box-sizing: border-box;
  padding: 0 4px;
`;

const Image = styled.img`
  width: 100%;
  margin-top: 4px;
  border-radius: 4px;
  clip-path: ${(props) => (props.circle ? "circle()" : "")};
`;

const Section3TextImage = styled.div`
  display: flex;
  margin: 30px 0;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 20px;

  @media (max-width: 768px) {
    width: auto;
  }
`;

const ImageSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 20px;

  @media (max-width: 768px) {
    width: auto;
    display: ${(props) => (props.hideOnMobile ? "none" : "flex")};
  }
`;

const MobileImageSection = styled.div`
  display: none;
  flex-direction: column;
  width: 50%;
  padding-top: 20px;

  @media (max-width: 768px) {
    width: auto;
    display: flex;
  }
`;

// const Section4Text = styled.div`
//   color: white;
//   font-size: 1em;
//   font-weight: 700;
//   padding-bottom: 50px;
// `;

const TeamContainer = styled.div`
  display: flex;
  margin-top: 50px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin-top: 0;
  }
`;

const TeamSection = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 3em;
  font-family: "upheavtt";
  color: #13237b;
  margin-bottom: 100px;
  margin-top: 50px;
  padding: 20px;

  @media (max-width: 768px) {
    font-size: 2em;
    width: auto;
    display: flex;
  }
`;

const TeamMember = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0px 20px;
  width: 25%;

  @media (max-width: 1024px) {
    padding: 0px 10px;
  }

  @media (max-width: 768px) {
    padding: 0px 15px;
    width: 50%;
    margin: 40px 0;
  }

  @media (max-width: 480px) {
    width: 80%;
    margin: 40px 0;
  }
`;

const TwitterIcon = styled.img`
  height: 80%;
  filter: invert(9%) sepia(98%) saturate(2308%) hue-rotate(220deg)
    brightness(96%) contrast(100%);
  margin-left: 5px;
`;

const TeamMemberName = styled.a`
  display: flex;
  text-decoration: none;
  cursor: pointer;
  font-size: 24px;
  font-weight: 700;
  color: red;
  padding-top: 10px;
  text-align: center;
  align-items: center;

  @media (max-width: 1024px) {
    font-size: 18px;
  }

  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const TeamMemberRole = styled.div`
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  padding-top: 10px;
  text-align: center;

  @media (max-width: 1024px) {
    font-size: 14px;
  }

  @media (max-width: 768px) {
    font-size: 20px;
  }

  @media (max-width: 480px) {
    font-size: 20px;
    text-align: center;
  }
`;

// const Section4Title = styled.div`
//   font-size: 3em;
//   font-family: "upheavtt";
//   margin-bottom: 30px;

//   @media (max-width: 768px) {
//     font-size: 2em;
//   }
// `;

const App = () => {
  let particles = [];
  for (var i = 0; i < 50; i++) {
    particles.push(<div class="particle" />);
  }

  return (
    <AppWrapper>
      <ParticleContainer>{particles}</ParticleContainer>
      <ColoredBG />
      <Header />
      <SectionsContainer>
        <Section1>
          <KoalasImage src={Koalas} />
        </Section1>
        <Section2>
          <MVDPTextBig>MVPD</MVDPTextBig>
          <MVDPTextFull>Metaverse police department</MVDPTextFull>
          <MintSection />
          {/* <IntroSection /> */}
          <UserManual />
        </Section2>
        <Section3>
          <Section3TextImage>
            <TextSection>
              <Title>About the koalection</Title>
              <ParagraphText>
                The Koala Kops are a low-supply Ethereum squad, ready to throw
                stuff at scammers.
                <br />
                <br />
                911 genesis Koala Kops will be free to mint on January 29th.
                <br />
                <br />
                After genesis recruitment, the headkoarters will communicate the
                kopmap.
              </ParagraphText>
            </TextSection>
            <ImageSection>
              <ImageRow>
                <ImageColumn>
                  <Image src={NFT1} alt="image" />
                  <Image src={NFT2} alt="image" />
                </ImageColumn>
                <ImageColumn>
                  <Image src={NFT3} alt="image" />
                  <Image src={NFT4} alt="image" />
                </ImageColumn>
              </ImageRow>
            </ImageSection>
          </Section3TextImage>
          <Section3TextImage>
            <ImageSection hideOnMobile={true}>
              <Image src={MoonGIF} alt="image" />
            </ImageSection>
            <TextSection>
              <Title>Are you Koalified?</Title>
              <ParagraphText>
                So you want to join the Metaverse Police Department and want to
                know what it takes to become a Koala Kop?
                <br />
                <br />
                1. When life gives you lemons, you will throw them at scammers
                <br />
                <br />
                2. If life gives you tomatoes, then throw that instead, it works
                too and has a bigger splash radius
                <br />
                <br />
                3. Take it easy, we’re just having fun
                <br />
              </ParagraphText>
              <JoinCommunityTextButton />
              <MobileImageSection>
                <Image src={MoonGIF} alt="image" />
              </MobileImageSection>
            </TextSection>
          </Section3TextImage>
        </Section3>
        <TeamSection>
          <div>Meet our team</div>
          <TeamContainer>
            <TeamMember>
              <Image circle src={TM1} alt="image" />
              <TeamMemberName
                href="https://twitter.com/esquinzo_"
                target="_blank"
              >
                esquinzo <TwitterIcon src={Twitter} />
              </TeamMemberName>
              <TeamMemberRole>Developer</TeamMemberRole>
            </TeamMember>
            <TeamMember>
              <Image circle src={TM2} alt="image" />
              <TeamMemberName href="https://twitter.com/alks00" target="_blank">
                alks <TwitterIcon src={Twitter} />
              </TeamMemberName>
              <TeamMemberRole>Developer</TeamMemberRole>
            </TeamMember>
            <TeamMember>
              <Image circle src={TM3} alt="image" />
              <TeamMemberName
                href="https://twitter.com/lucasthegloucas"
                target="_blank"
              >
                Lucas <TwitterIcon src={Twitter} />
              </TeamMemberName>
              <TeamMemberRole>Marketing</TeamMemberRole>
            </TeamMember>
            <TeamMember>
              <Image circle src={TM4} alt="image" />
              <TeamMemberName
                href="https://twitter.com/UnfuneralOD"
                target="_blank"
              >
                UnfuneralOD <TwitterIcon src={Twitter} />
              </TeamMemberName>
              <TeamMemberRole>Artist</TeamMemberRole>
            </TeamMember>
          </TeamContainer>
        </TeamSection>
        {/* <Section4>
          <Section4Text>
            <Section4Title>AUTHENTICITY AND TRANSPARENCY</Section4Title>
            <br />
            Everything we do is publicly shared in our Discord server, with
            daily announcements of progress made and upcoming milestones.
            <br />
            <br />
            We also have an exclusive channel dedicated to co-building, where
            our community's top contributors can shape the project's future.
            <br />
            <br />
            Join the adventure and contribute to our mission: let's bring a
            smile to people's faces while fighting scammers.
            <br />
            <br />
            Full marketing strategy, team doxx, and more on our Discord.
          </Section4Text>
          <JoinCommunityTextButton />
        </Section4> */}
      </SectionsContainer>
      <Footer />
    </AppWrapper>
  );
};

export default App;
