import Scam from "../../../Scam.png";
import styled from "styled-components";
import { useState } from "react";
import TargetLove from "../../../TargetLove.png";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const ChoiceWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin: 50px 50px;
`;

const Choice = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(#e740cb, #ee7bb4);
  align-items: center;
  padding: 20px;
  border-radius: 15px;
  max-width: 150px;
  height: 200px;
  width: 25%;

  font-size: 18px;
  font-weight: 800;
  border: 3px solid white;
  cursor: pointer;
  opacity: ${(props) => (props.isSelected ? 1 : 0.7)};

  :hover {
    opacity: 1;
  }

  transition: 0.1s ease;
`;

const ScamImage = styled.img`
  width: 80%;
  border-radius: 100px;
  border: solid 1px white;
`;

const StyledInput = styled.input`
  display: none;
`;

const CustomUpload = styled.label`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const NextButton = styled.button`
  background: #c2fd65;
  color: #4eae45;
  padding: 20px 50px;
  font-size: 30px;
  font-weight: 800;
  border-radius: 100px;
  border: none;
  cursor: pointer;
  outline: inherit;
  margin: 50px 0;
  font-family: "minecraft";
`;

const ChooseTarget = ({ onScamSelected, isTargetScam }) => {
  const [imageScam, setImageScam] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);

  var resizeImage = function (settings) {
    var file = settings.file;
    var maxSize = settings.maxSize;
    var reader = new FileReader();
    var image = new Image();
    var canvas = document.createElement("canvas");
    var dataURItoBlob = function (dataURI) {
      var bytes =
        dataURI.split(",")[0].indexOf("base64") >= 0
          ? atob(dataURI.split(",")[1])
          : unescape(dataURI.split(",")[1]);
      var mime = dataURI.split(",")[0].split(":")[1].split(";")[0];
      var max = bytes.length;
      var ia = new Uint8Array(max);
      for (var i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
      return new Blob([ia], { type: mime });
    };
    var resize = function () {
      var width = maxSize;
      var height = maxSize;

      canvas.width = width;
      canvas.height = height;

      var hRatio = canvas.width / image.width;
      var vRatio = canvas.height / image.height;
      var ratio = Math.max(hRatio, vRatio);
      var centerShift_x = (canvas.width - image.width * ratio) / 2;
      var centerShift_y = (canvas.height - image.height * ratio) / 2;
      canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height);
      canvas
        .getContext("2d")
        .drawImage(
          image,
          0,
          0,
          image.width,
          image.height,
          centerShift_x,
          centerShift_y,
          image.width * ratio,
          image.height * ratio
        );

      var dataUrl = canvas.toDataURL("image/jpeg");
      return dataURItoBlob(dataUrl);
    };
    return new Promise(function (ok, no) {
      if (!file.type.match(/image.*/)) {
        no(new Error("Not an image"));
        return;
      }
      reader.onload = function (readerEvent) {
        image.onload = function () {
          return ok(resize());
        };
        image.src = readerEvent.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  const onImageChange = async (event) => {
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
      console.log("file:", file);
      const config = {
        file: file,
        maxSize: 224,
      };
      const resizedBlob = await resizeImage(config);
      var resizedFile = new File([resizedBlob], "scameImage.jpeg", {
        type: resizedBlob.type,
      });

      setImageScam(URL.createObjectURL(resizedFile));
      setSelectedIndex(1);
    }
  };

  return (
    <Container>
      <ChoiceWrapper>
        <Choice
          isSelected={selectedIndex === 0}
          onClick={() => {
            setSelectedIndex(0);
          }}
        >
          <ScamImage src={isTargetScam ? Scam : TargetLove} />
        </Choice>
        <Choice
          isSelected={selectedIndex === 1}
          onClick={() => {
            setSelectedIndex(1);
          }}
        >
          <CustomUpload for="file-upload" class="custom-file-upload">
            {imageScam ? (
              <ScamImage src={imageScam} />
            ) : (
              <span>Upload Image</span>
            )}
          </CustomUpload>
          <StyledInput
            id="file-upload"
            type="file"
            name="scamImage"
            onChange={onImageChange}
            accept=".jpg,.png,.jpeg"
          />
        </Choice>
      </ChoiceWrapper>
      <NextButton
        onClick={() => {
          onScamSelected(
            selectedIndex === 0 ? (isTargetScam ? Scam : TargetLove) : imageScam
          );
        }}
      >
        Next
      </NextButton>
    </Container>
  );
};

export default ChooseTarget;
