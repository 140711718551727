import { getAnalytics, logEvent } from "firebase/analytics";

import ChooseTarget from "./ChooseTarget";
import ChooseVehicle from "./ChooseVehicle";
import ChooseWeapon from "./ChooseWeapon";
import CustomProgressBar from "../ProgressBar";
import bg0 from "../../Gif-img/Background/frame_00_delay-0.05s.gif";
import bg1 from "../../Gif-img/Background/frame_01_delay-0.05s.gif";
import bg10 from "../../Gif-img/Background/frame_10_delay-0.05s.gif";
import bg11 from "../../Gif-img/Background/frame_11_delay-0.05s.gif";
import bg12 from "../../Gif-img/Background/frame_12_delay-0.05s.gif";
import bg13 from "../../Gif-img/Background/frame_13_delay-0.05s.gif";
import bg14 from "../../Gif-img/Background/frame_14_delay-0.05s.gif";
import bg15 from "../../Gif-img/Background/frame_15_delay-0.05s.gif";
import bg16 from "../../Gif-img/Background/frame_16_delay-0.05s.gif";
import bg17 from "../../Gif-img/Background/frame_17_delay-0.05s.gif";
import bg18 from "../../Gif-img/Background/frame_18_delay-0.05s.gif";
import bg19 from "../../Gif-img/Background/frame_19_delay-0.05s.gif";
import bg2 from "../../Gif-img/Background/frame_02_delay-0.05s.gif";
import bg20 from "../../Gif-img/Background/frame_20_delay-0.05s.gif";
import bg21 from "../../Gif-img/Background/frame_21_delay-0.05s.gif";
import bg22 from "../../Gif-img/Background/frame_22_delay-0.05s.gif";
import bg23 from "../../Gif-img/Background/frame_23_delay-0.05s.gif";
import bg24 from "../../Gif-img/Background/frame_24_delay-0.05s.gif";
import bg25 from "../../Gif-img/Background/frame_25_delay-0.05s.gif";
import bg26 from "../../Gif-img/Background/frame_26_delay-0.05s.gif";
import bg27 from "../../Gif-img/Background/frame_27_delay-0.05s.gif";
import bg28 from "../../Gif-img/Background/frame_28_delay-0.05s.gif";
import bg29 from "../../Gif-img/Background/frame_29_delay-0.05s.gif";
import bg3 from "../../Gif-img/Background/frame_03_delay-0.05s.gif";
import bg30 from "../../Gif-img/Background/frame_30_delay-0.05s.gif";
import bg31 from "../../Gif-img/Background/frame_31_delay-0.05s.gif";
import bg32 from "../../Gif-img/Background/frame_32_delay-0.05s.gif";
import bg33 from "../../Gif-img/Background/frame_33_delay-0.05s.gif";
import bg34 from "../../Gif-img/Background/frame_34_delay-0.05s.gif";
import bg35 from "../../Gif-img/Background/frame_35_delay-0.05s.gif";
import bg36 from "../../Gif-img/Background/frame_36_delay-0.05s.gif";
import bg37 from "../../Gif-img/Background/frame_37_delay-0.05s.gif";
import bg38 from "../../Gif-img/Background/frame_38_delay-0.05s.gif";
import bg39 from "../../Gif-img/Background/frame_39_delay-0.05s.gif";
import bg4 from "../../Gif-img/Background/frame_04_delay-0.05s.gif";
import bg40 from "../../Gif-img/Background/frame_40_delay-0.05s.gif";
import bg41 from "../../Gif-img/Background/frame_41_delay-0.05s.gif";
import bg42 from "../../Gif-img/Background/frame_42_delay-0.05s.gif";
import bg43 from "../../Gif-img/Background/frame_43_delay-0.05s.gif";
import bg44 from "../../Gif-img/Background/frame_44_delay-0.05s.gif";
import bg45 from "../../Gif-img/Background/frame_45_delay-0.05s.gif";
import bg46 from "../../Gif-img/Background/frame_46_delay-0.05s.gif";
import bg47 from "../../Gif-img/Background/frame_47_delay-0.05s.gif";
import bg48 from "../../Gif-img/Background/frame_48_delay-0.05s.gif";
import bg49 from "../../Gif-img/Background/frame_49_delay-0.05s.gif";
import bg5 from "../../Gif-img/Background/frame_05_delay-0.05s.gif";
import bg50 from "../../Gif-img/Background/frame_50_delay-0.05s.gif";
import bg51 from "../../Gif-img/Background/frame_51_delay-0.05s.gif";
import bg52 from "../../Gif-img/Background/frame_52_delay-0.05s.gif";
import bg53 from "../../Gif-img/Background/frame_53_delay-0.05s.gif";
import bg54 from "../../Gif-img/Background/frame_54_delay-0.05s.gif";
import bg55 from "../../Gif-img/Background/frame_55_delay-0.05s.gif";
import bg6 from "../../Gif-img/Background/frame_06_delay-0.05s.gif";
import bg7 from "../../Gif-img/Background/frame_07_delay-0.05s.gif";
import bg8 from "../../Gif-img/Background/frame_08_delay-0.05s.gif";
import bg9 from "../../Gif-img/Background/frame_09_delay-0.05s.gif";
import bgFireworks27 from "../../Gif-img/BackgroundFireworks/frame_00_delay-0.05s.gif";
import bgFireworks28 from "../../Gif-img/BackgroundFireworks/frame_01_delay-0.05s.gif";
import bgFireworks29 from "../../Gif-img/BackgroundFireworks/frame_02_delay-0.05s.gif";
import bgFireworks30 from "../../Gif-img/BackgroundFireworks/frame_08_delay-0.05s.gif";
import bgFireworks31 from "../../Gif-img/BackgroundFireworks/frame_09_delay-0.05s.gif";
import bgFireworks32 from "../../Gif-img/BackgroundFireworks/frame_10_delay-0.05s.gif";
import bgFireworks33 from "../../Gif-img/BackgroundFireworks/frame_11_delay-0.05s.gif";
import bgFireworks34 from "../../Gif-img/BackgroundFireworks/frame_12_delay-0.05s.gif";
import bgFireworks35 from "../../Gif-img/BackgroundFireworks/frame_17_delay-0.05s.gif";
import bgFireworks36 from "../../Gif-img/BackgroundFireworks/frame_18_delay-0.05s.gif";
import bgFireworks37 from "../../Gif-img/BackgroundFireworks/frame_19_delay-0.05s.gif";
import bgFireworks38 from "../../Gif-img/BackgroundFireworks/frame_20_delay-0.05s.gif";
import bgFireworks39 from "../../Gif-img/BackgroundFireworks/frame_22_delay-0.05s.gif";
import bgFireworks40 from "../../Gif-img/BackgroundFireworks/frame_23_delay-0.05s.gif";
import bgFireworks41 from "../../Gif-img/BackgroundFireworks/frame_24_delay-0.05s.gif";
import bgFireworks42 from "../../Gif-img/BackgroundFireworks/frame_25_delay-0.05s.gif";
import bgFireworks43 from "../../Gif-img/BackgroundFireworks/frame_26_delay-0.05s.gif";
import bgFireworks44 from "../../Gif-img/BackgroundFireworks/frame_27_delay-0.05s.gif";
import bgFireworks45 from "../../Gif-img/BackgroundFireworks/frame_28_delay-0.05s.gif";
import bgFireworks46 from "../../Gif-img/BackgroundFireworks/frame_29_delay-0.05s.gif";
import bgFireworks47 from "../../Gif-img/BackgroundFireworks/frame_30_delay-0.05s.gif";
import bgFireworks48 from "../../Gif-img/BackgroundFireworks/frame_31_delay-0.05s.gif";
import bgFireworks49 from "../../Gif-img/BackgroundFireworks/frame_32_delay-0.05s.gif";
import bgFireworks50 from "../../Gif-img/BackgroundFireworks/frame_33_delay-0.05s.gif";
import bgFireworks51 from "../../Gif-img/BackgroundFireworks/frame_34_delay-0.05s.gif";
import bgFireworks52 from "../../Gif-img/BackgroundFireworks/frame_35_delay-0.05s.gif";
import bgFireworks53 from "../../Gif-img/BackgroundFireworks/frame_41_delay-0.05s.gif";
import bgFireworks54 from "../../Gif-img/BackgroundFireworks/frame_42_delay-0.05s.gif";
import bgFireworks55 from "../../Gif-img/BackgroundFireworks/frame_43_delay-0.05s.gif";
import bgFireworks56 from "../../Gif-img/BackgroundFireworks/frame_44_delay-0.05s.gif";
import bgFireworks57 from "../../Gif-img/BackgroundFireworks/frame_45_delay-0.05s.gif";
import bgFireworks58 from "../../Gif-img/BackgroundFireworks/frame_46_delay-0.05s.gif";
import bgFireworks59 from "../../Gif-img/BackgroundFireworks/frame_47_delay-0.05s.gif";
import jetComing0 from "../../Gif-img/JetComing/frame_00_delay-0.05s.gif";
// import jetComing9 from "../../Gif-img/JetComing/frame_09_delay-0.05s.gif";
import jetComing10 from "../../Gif-img/JetComing/frame_10_delay-0.05s.gif";
// import jetComing11 from "../../Gif-img/JetComing/frame_11_delay-0.05s.gif";
import jetComing12 from "../../Gif-img/JetComing/frame_12_delay-0.05s.gif";
// import jetComing13 from "../../Gif-img/JetComing/frame_13_delay-0.05s.gif";
import jetComing14 from "../../Gif-img/JetComing/frame_14_delay-0.05s.gif";
// import jetComing15 from "../../Gif-img/JetComing/frame_15_delay-0.05s.gif";
import jetComing16 from "../../Gif-img/JetComing/frame_16_delay-0.05s.gif";
// import jetComing17 from "../../Gif-img/JetComing/frame_17_delay-0.05s.gif";
import jetComing18 from "../../Gif-img/JetComing/frame_18_delay-0.05s.gif";
// import jetComing1 from "../../Gif-img/JetComing/frame_01_delay-0.05s.gif";
import jetComing2 from "../../Gif-img/JetComing/frame_02_delay-0.05s.gif";
// import jetComing19 from "../../Gif-img/JetComing/frame_19_delay-0.05s.gif";
import jetComing20 from "../../Gif-img/JetComing/frame_20_delay-0.05s.gif";
// import jetComing21 from "../../Gif-img/JetComing/frame_21_delay-0.05s.gif";
import jetComing22 from "../../Gif-img/JetComing/frame_22_delay-0.05s.gif";
// import jetComing23 from "../../Gif-img/JetComing/frame_23_delay-0.05s.gif";
import jetComing24 from "../../Gif-img/JetComing/frame_24_delay-0.05s.gif";
// import jetComing25 from "../../Gif-img/JetComing/frame_25_delay-0.05s.gif";
import jetComing26 from "../../Gif-img/JetComing/frame_26_delay-0.05s.gif";
// import jetComing27 from "../../Gif-img/JetComing/frame_27_delay-0.05s.gif";
import jetComing28 from "../../Gif-img/JetComing/frame_28_delay-0.05s.gif";
// import jetComing3 from "../../Gif-img/JetComing/frame_03_delay-0.05s.gif";
import jetComing4 from "../../Gif-img/JetComing/frame_04_delay-0.05s.gif";
// import jetComing5 from "../../Gif-img/JetComing/frame_05_delay-0.05s.gif";
import jetComing6 from "../../Gif-img/JetComing/frame_06_delay-0.05s.gif";
// import jetComing7 from "../../Gif-img/JetComing/frame_07_delay-0.05s.gif";
import jetComing8 from "../../Gif-img/JetComing/frame_08_delay-0.05s.gif";
import jetLeavingHeart0 from "../../Gif-img/JetLeavingHeart/frame_00_delay-0.05s.gif";
import jetLeavingHeart1 from "../../Gif-img/JetLeavingHeart/frame_01_delay-0.05s.gif";
import jetLeavingHeart10 from "../../Gif-img/JetLeavingHeart/frame_10_delay-0.05s.gif";
import jetLeavingHeart11 from "../../Gif-img/JetLeavingHeart/frame_11_delay-0.05s.gif";
import jetLeavingHeart12 from "../../Gif-img/JetLeavingHeart/frame_12_delay-0.05s.gif";
import jetLeavingHeart13 from "../../Gif-img/JetLeavingHeart/frame_13_delay-0.05s.gif";
import jetLeavingHeart14 from "../../Gif-img/JetLeavingHeart/frame_14_delay-0.05s.gif";
import jetLeavingHeart15 from "../../Gif-img/JetLeavingHeart/frame_15_delay-0.05s.gif";
import jetLeavingHeart16 from "../../Gif-img/JetLeavingHeart/frame_16_delay-0.05s.gif";
import jetLeavingHeart17 from "../../Gif-img/JetLeavingHeart/frame_17_delay-0.05s.gif";
import jetLeavingHeart18 from "../../Gif-img/JetLeavingHeart/frame_18_delay-0.05s.gif";
import jetLeavingHeart19 from "../../Gif-img/JetLeavingHeart/frame_19_delay-0.05s.gif";
import jetLeavingHeart2 from "../../Gif-img/JetLeavingHeart/frame_02_delay-0.05s.gif";
import jetLeavingHeart20 from "../../Gif-img/JetLeavingHeart/frame_20_delay-0.05s.gif";
import jetLeavingHeart21 from "../../Gif-img/JetLeavingHeart/frame_21_delay-0.05s.gif";
import jetLeavingHeart22 from "../../Gif-img/JetLeavingHeart/frame_22_delay-0.05s.gif";
import jetLeavingHeart23 from "../../Gif-img/JetLeavingHeart/frame_23_delay-0.05s.gif";
import jetLeavingHeart24 from "../../Gif-img/JetLeavingHeart/frame_24_delay-0.05s.gif";
import jetLeavingHeart25 from "../../Gif-img/JetLeavingHeart/frame_25_delay-0.05s.gif";
import jetLeavingHeart26 from "../../Gif-img/JetLeavingHeart/frame_26_delay-0.05s.gif";
import jetLeavingHeart27 from "../../Gif-img/JetLeavingHeart/frame_27_delay-0.05s.gif";
import jetLeavingHeart28 from "../../Gif-img/JetLeavingHeart/frame_28_delay-0.05s.gif";
import jetLeavingHeart3 from "../../Gif-img/JetLeavingHeart/frame_03_delay-0.05s.gif";
import jetLeavingHeart4 from "../../Gif-img/JetLeavingHeart/frame_04_delay-0.05s.gif";
import jetLeavingHeart5 from "../../Gif-img/JetLeavingHeart/frame_05_delay-0.05s.gif";
import jetLeavingHeart6 from "../../Gif-img/JetLeavingHeart/frame_06_delay-0.05s.gif";
import jetLeavingHeart7 from "../../Gif-img/JetLeavingHeart/frame_07_delay-0.05s.gif";
import jetLeavingHeart8 from "../../Gif-img/JetLeavingHeart/frame_08_delay-0.05s.gif";
import jetLeavingHeart9 from "../../Gif-img/JetLeavingHeart/frame_09_delay-0.05s.gif";
import jetLeavingLemon0 from "../../Gif-img/JetLeavingLemon/frame_00_delay-0.05s.gif";
import jetLeavingLemon1 from "../../Gif-img/JetLeavingLemon/frame_01_delay-0.05s.gif";
import jetLeavingLemon10 from "../../Gif-img/JetLeavingLemon/frame_10_delay-0.05s.gif";
import jetLeavingLemon11 from "../../Gif-img/JetLeavingLemon/frame_11_delay-0.05s.gif";
import jetLeavingLemon12 from "../../Gif-img/JetLeavingLemon/frame_12_delay-0.05s.gif";
import jetLeavingLemon13 from "../../Gif-img/JetLeavingLemon/frame_13_delay-0.05s.gif";
import jetLeavingLemon14 from "../../Gif-img/JetLeavingLemon/frame_14_delay-0.05s.gif";
import jetLeavingLemon15 from "../../Gif-img/JetLeavingLemon/frame_15_delay-0.05s.gif";
import jetLeavingLemon16 from "../../Gif-img/JetLeavingLemon/frame_16_delay-0.05s.gif";
import jetLeavingLemon17 from "../../Gif-img/JetLeavingLemon/frame_17_delay-0.05s.gif";
import jetLeavingLemon18 from "../../Gif-img/JetLeavingLemon/frame_18_delay-0.05s.gif";
import jetLeavingLemon19 from "../../Gif-img/JetLeavingLemon/frame_19_delay-0.05s.gif";
import jetLeavingLemon2 from "../../Gif-img/JetLeavingLemon/frame_02_delay-0.05s.gif";
import jetLeavingLemon20 from "../../Gif-img/JetLeavingLemon/frame_20_delay-0.05s.gif";
import jetLeavingLemon21 from "../../Gif-img/JetLeavingLemon/frame_21_delay-0.05s.gif";
import jetLeavingLemon22 from "../../Gif-img/JetLeavingLemon/frame_22_delay-0.05s.gif";
import jetLeavingLemon23 from "../../Gif-img/JetLeavingLemon/frame_23_delay-0.05s.gif";
import jetLeavingLemon24 from "../../Gif-img/JetLeavingLemon/frame_24_delay-0.05s.gif";
import jetLeavingLemon25 from "../../Gif-img/JetLeavingLemon/frame_25_delay-0.05s.gif";
import jetLeavingLemon26 from "../../Gif-img/JetLeavingLemon/frame_26_delay-0.05s.gif";
import jetLeavingLemon27 from "../../Gif-img/JetLeavingLemon/frame_27_delay-0.05s.gif";
import jetLeavingLemon28 from "../../Gif-img/JetLeavingLemon/frame_28_delay-0.05s.gif";
import jetLeavingLemon3 from "../../Gif-img/JetLeavingLemon/frame_03_delay-0.05s.gif";
import jetLeavingLemon4 from "../../Gif-img/JetLeavingLemon/frame_04_delay-0.05s.gif";
import jetLeavingLemon5 from "../../Gif-img/JetLeavingLemon/frame_05_delay-0.05s.gif";
import jetLeavingLemon6 from "../../Gif-img/JetLeavingLemon/frame_06_delay-0.05s.gif";
import jetLeavingLemon7 from "../../Gif-img/JetLeavingLemon/frame_07_delay-0.05s.gif";
import jetLeavingLemon8 from "../../Gif-img/JetLeavingLemon/frame_08_delay-0.05s.gif";
import jetLeavingLemon9 from "../../Gif-img/JetLeavingLemon/frame_09_delay-0.05s.gif";
import jetLeavingTomato0 from "../../Gif-img/JetLeavingTomato/frame_00_delay-0.05s.gif";
import jetLeavingTomato1 from "../../Gif-img/JetLeavingTomato/frame_01_delay-0.05s.gif";
import jetLeavingTomato10 from "../../Gif-img/JetLeavingTomato/frame_10_delay-0.05s.gif";
import jetLeavingTomato11 from "../../Gif-img/JetLeavingTomato/frame_11_delay-0.05s.gif";
import jetLeavingTomato12 from "../../Gif-img/JetLeavingTomato/frame_12_delay-0.05s.gif";
import jetLeavingTomato13 from "../../Gif-img/JetLeavingTomato/frame_13_delay-0.05s.gif";
import jetLeavingTomato14 from "../../Gif-img/JetLeavingTomato/frame_14_delay-0.05s.gif";
import jetLeavingTomato15 from "../../Gif-img/JetLeavingTomato/frame_15_delay-0.05s.gif";
import jetLeavingTomato16 from "../../Gif-img/JetLeavingTomato/frame_16_delay-0.05s.gif";
import jetLeavingTomato17 from "../../Gif-img/JetLeavingTomato/frame_17_delay-0.05s.gif";
import jetLeavingTomato18 from "../../Gif-img/JetLeavingTomato/frame_18_delay-0.05s.gif";
import jetLeavingTomato19 from "../../Gif-img/JetLeavingTomato/frame_19_delay-0.05s.gif";
import jetLeavingTomato2 from "../../Gif-img/JetLeavingTomato/frame_02_delay-0.05s.gif";
import jetLeavingTomato20 from "../../Gif-img/JetLeavingTomato/frame_20_delay-0.05s.gif";
import jetLeavingTomato21 from "../../Gif-img/JetLeavingTomato/frame_21_delay-0.05s.gif";
import jetLeavingTomato22 from "../../Gif-img/JetLeavingTomato/frame_22_delay-0.05s.gif";
import jetLeavingTomato23 from "../../Gif-img/JetLeavingTomato/frame_23_delay-0.05s.gif";
import jetLeavingTomato24 from "../../Gif-img/JetLeavingTomato/frame_24_delay-0.05s.gif";
import jetLeavingTomato25 from "../../Gif-img/JetLeavingTomato/frame_25_delay-0.05s.gif";
import jetLeavingTomato26 from "../../Gif-img/JetLeavingTomato/frame_26_delay-0.05s.gif";
import jetLeavingTomato27 from "../../Gif-img/JetLeavingTomato/frame_27_delay-0.05s.gif";
import jetLeavingTomato28 from "../../Gif-img/JetLeavingTomato/frame_28_delay-0.05s.gif";
import jetLeavingTomato3 from "../../Gif-img/JetLeavingTomato/frame_03_delay-0.05s.gif";
import jetLeavingTomato4 from "../../Gif-img/JetLeavingTomato/frame_04_delay-0.05s.gif";
import jetLeavingTomato5 from "../../Gif-img/JetLeavingTomato/frame_05_delay-0.05s.gif";
import jetLeavingTomato6 from "../../Gif-img/JetLeavingTomato/frame_06_delay-0.05s.gif";
import jetLeavingTomato7 from "../../Gif-img/JetLeavingTomato/frame_07_delay-0.05s.gif";
import jetLeavingTomato8 from "../../Gif-img/JetLeavingTomato/frame_08_delay-0.05s.gif";
import jetLeavingTomato9 from "../../Gif-img/JetLeavingTomato/frame_09_delay-0.05s.gif";
import jetThrowHeart0 from "../../Gif-img/JetThrowHeart/frame_00_delay-0.05s.gif";
import jetThrowHeart1 from "../../Gif-img/JetThrowHeart/frame_01_delay-0.05s.gif";
import jetThrowHeart10 from "../../Gif-img/JetThrowHeart/frame_10_delay-0.05s.gif";
import jetThrowHeart11 from "../../Gif-img/JetThrowHeart/frame_11_delay-0.05s.gif";
import jetThrowHeart12 from "../../Gif-img/JetThrowHeart/frame_12_delay-0.05s.gif";
import jetThrowHeart13 from "../../Gif-img/JetThrowHeart/frame_13_delay-0.05s.gif";
import jetThrowHeart14 from "../../Gif-img/JetThrowHeart/frame_14_delay-0.05s.gif";
import jetThrowHeart15 from "../../Gif-img/JetThrowHeart/frame_15_delay-0.05s.gif";
import jetThrowHeart16 from "../../Gif-img/JetThrowHeart/frame_16_delay-0.05s.gif";
import jetThrowHeart17 from "../../Gif-img/JetThrowHeart/frame_17_delay-0.05s.gif";
import jetThrowHeart18 from "../../Gif-img/JetThrowHeart/frame_18_delay-0.05s.gif";
import jetThrowHeart19 from "../../Gif-img/JetThrowHeart/frame_19_delay-0.05s.gif";
import jetThrowHeart2 from "../../Gif-img/JetThrowHeart/frame_02_delay-0.05s.gif";
import jetThrowHeart20 from "../../Gif-img/JetThrowHeart/frame_20_delay-0.05s.gif";
import jetThrowHeart21 from "../../Gif-img/JetThrowHeart/frame_21_delay-0.05s.gif";
import jetThrowHeart3 from "../../Gif-img/JetThrowHeart/frame_03_delay-0.05s.gif";
import jetThrowHeart4 from "../../Gif-img/JetThrowHeart/frame_04_delay-0.05s.gif";
import jetThrowHeart5 from "../../Gif-img/JetThrowHeart/frame_05_delay-0.05s.gif";
import jetThrowHeart6 from "../../Gif-img/JetThrowHeart/frame_06_delay-0.05s.gif";
import jetThrowHeart7 from "../../Gif-img/JetThrowHeart/frame_07_delay-0.05s.gif";
import jetThrowHeart8 from "../../Gif-img/JetThrowHeart/frame_08_delay-0.05s.gif";
import jetThrowHeart9 from "../../Gif-img/JetThrowHeart/frame_09_delay-0.05s.gif";
import jetThrowLemon0 from "../../Gif-img/JetThrowLemon/frame_00_delay-0.05s.gif";
import jetThrowLemon1 from "../../Gif-img/JetThrowLemon/frame_01_delay-0.05s.gif";
import jetThrowLemon10 from "../../Gif-img/JetThrowLemon/frame_10_delay-0.05s.gif";
import jetThrowLemon11 from "../../Gif-img/JetThrowLemon/frame_11_delay-0.05s.gif";
import jetThrowLemon12 from "../../Gif-img/JetThrowLemon/frame_12_delay-0.05s.gif";
import jetThrowLemon13 from "../../Gif-img/JetThrowLemon/frame_13_delay-0.05s.gif";
import jetThrowLemon14 from "../../Gif-img/JetThrowLemon/frame_14_delay-0.05s.gif";
import jetThrowLemon15 from "../../Gif-img/JetThrowLemon/frame_15_delay-0.05s.gif";
import jetThrowLemon16 from "../../Gif-img/JetThrowLemon/frame_16_delay-0.05s.gif";
import jetThrowLemon17 from "../../Gif-img/JetThrowLemon/frame_17_delay-0.05s.gif";
import jetThrowLemon18 from "../../Gif-img/JetThrowLemon/frame_18_delay-0.05s.gif";
import jetThrowLemon19 from "../../Gif-img/JetThrowLemon/frame_19_delay-0.05s.gif";
import jetThrowLemon2 from "../../Gif-img/JetThrowLemon/frame_02_delay-0.05s.gif";
import jetThrowLemon20 from "../../Gif-img/JetThrowLemon/frame_20_delay-0.05s.gif";
import jetThrowLemon21 from "../../Gif-img/JetThrowLemon/frame_21_delay-0.05s.gif";
import jetThrowLemon3 from "../../Gif-img/JetThrowLemon/frame_03_delay-0.05s.gif";
import jetThrowLemon4 from "../../Gif-img/JetThrowLemon/frame_04_delay-0.05s.gif";
import jetThrowLemon5 from "../../Gif-img/JetThrowLemon/frame_05_delay-0.05s.gif";
import jetThrowLemon6 from "../../Gif-img/JetThrowLemon/frame_06_delay-0.05s.gif";
import jetThrowLemon7 from "../../Gif-img/JetThrowLemon/frame_07_delay-0.05s.gif";
import jetThrowLemon8 from "../../Gif-img/JetThrowLemon/frame_08_delay-0.05s.gif";
import jetThrowLemon9 from "../../Gif-img/JetThrowLemon/frame_09_delay-0.05s.gif";
import jetThrowTomato0 from "../../Gif-img/JetThrowTomato/frame_00_delay-0.05s.gif";
import jetThrowTomato1 from "../../Gif-img/JetThrowTomato/frame_01_delay-0.05s.gif";
import jetThrowTomato10 from "../../Gif-img/JetThrowTomato/frame_10_delay-0.05s.gif";
import jetThrowTomato11 from "../../Gif-img/JetThrowTomato/frame_11_delay-0.05s.gif";
import jetThrowTomato12 from "../../Gif-img/JetThrowTomato/frame_12_delay-0.05s.gif";
import jetThrowTomato13 from "../../Gif-img/JetThrowTomato/frame_13_delay-0.05s.gif";
import jetThrowTomato14 from "../../Gif-img/JetThrowTomato/frame_14_delay-0.05s.gif";
import jetThrowTomato15 from "../../Gif-img/JetThrowTomato/frame_15_delay-0.05s.gif";
import jetThrowTomato16 from "../../Gif-img/JetThrowTomato/frame_16_delay-0.05s.gif";
import jetThrowTomato17 from "../../Gif-img/JetThrowTomato/frame_17_delay-0.05s.gif";
import jetThrowTomato18 from "../../Gif-img/JetThrowTomato/frame_18_delay-0.05s.gif";
import jetThrowTomato19 from "../../Gif-img/JetThrowTomato/frame_19_delay-0.05s.gif";
import jetThrowTomato2 from "../../Gif-img/JetThrowTomato/frame_02_delay-0.05s.gif";
import jetThrowTomato20 from "../../Gif-img/JetThrowTomato/frame_20_delay-0.05s.gif";
import jetThrowTomato21 from "../../Gif-img/JetThrowTomato/frame_21_delay-0.05s.gif";
import jetThrowTomato3 from "../../Gif-img/JetThrowTomato/frame_03_delay-0.05s.gif";
import jetThrowTomato4 from "../../Gif-img/JetThrowTomato/frame_04_delay-0.05s.gif";
import jetThrowTomato5 from "../../Gif-img/JetThrowTomato/frame_05_delay-0.05s.gif";
import jetThrowTomato6 from "../../Gif-img/JetThrowTomato/frame_06_delay-0.05s.gif";
import jetThrowTomato7 from "../../Gif-img/JetThrowTomato/frame_07_delay-0.05s.gif";
import jetThrowTomato8 from "../../Gif-img/JetThrowTomato/frame_08_delay-0.05s.gif";
import jetThrowTomato9 from "../../Gif-img/JetThrowTomato/frame_09_delay-0.05s.gif";
import mergeImages from "merge-images";
import scootComing0 from "../../Gif-img/ScootComing/frame_00_delay-0.05s.gif";
// import scootComing9 from "../../Gif-img/ScootComing/frame_09_delay-0.05s.gif";
import scootComing10 from "../../Gif-img/ScootComing/frame_10_delay-0.05s.gif";
// import scootComing11 from "../../Gif-img/ScootComing/frame_11_delay-0.05s.gif";
import scootComing12 from "../../Gif-img/ScootComing/frame_12_delay-0.05s.gif";
// import scootComing13 from "../../Gif-img/ScootComing/frame_13_delay-0.05s.gif";
import scootComing14 from "../../Gif-img/ScootComing/frame_14_delay-0.05s.gif";
// import scootComing15 from "../../Gif-img/ScootComing/frame_15_delay-0.05s.gif";
import scootComing16 from "../../Gif-img/ScootComing/frame_16_delay-0.05s.gif";
// import scootComing17 from "../../Gif-img/ScootComing/frame_17_delay-0.05s.gif";
import scootComing18 from "../../Gif-img/ScootComing/frame_18_delay-0.05s.gif";
// import scootComing1 from "../../Gif-img/ScootComing/frame_01_delay-0.05s.gif";
import scootComing2 from "../../Gif-img/ScootComing/frame_02_delay-0.05s.gif";
// import scootComing19 from "../../Gif-img/ScootComing/frame_19_delay-0.05s.gif";
import scootComing20 from "../../Gif-img/ScootComing/frame_20_delay-0.05s.gif";
// import scootComing21 from "../../Gif-img/ScootComing/frame_21_delay-0.05s.gif";
import scootComing22 from "../../Gif-img/ScootComing/frame_22_delay-0.05s.gif";
// import scootComing23 from "../../Gif-img/ScootComing/frame_23_delay-0.05s.gif";
import scootComing24 from "../../Gif-img/ScootComing/frame_24_delay-0.05s.gif";
// import scootComing25 from "../../Gif-img/ScootComing/frame_25_delay-0.05s.gif";
import scootComing26 from "../../Gif-img/ScootComing/frame_26_delay-0.05s.gif";
// import scootComing27 from "../../Gif-img/ScootComing/frame_27_delay-0.05s.gif";
import scootComing28 from "../../Gif-img/ScootComing/frame_28_delay-0.05s.gif";
// import scootComing29 from "../../Gif-img/ScootComing/frame_29_delay-0.05s.gif";
import scootComing30 from "../../Gif-img/ScootComing/frame_30_delay-0.05s.gif";
// import scootComing31 from "../../Gif-img/ScootComing/frame_31_delay-0.05s.gif";
import scootComing32 from "../../Gif-img/ScootComing/frame_32_delay-0.05s.gif";
// import scootComing33 from "../../Gif-img/ScootComing/frame_33_delay-0.05s.gif";
import scootComing34 from "../../Gif-img/ScootComing/frame_34_delay-0.05s.gif";
// import scootComing3 from "../../Gif-img/ScootComing/frame_03_delay-0.05s.gif";
import scootComing4 from "../../Gif-img/ScootComing/frame_04_delay-0.05s.gif";
// import scootComing5 from "../../Gif-img/ScootComing/frame_05_delay-0.05s.gif";
import scootComing6 from "../../Gif-img/ScootComing/frame_06_delay-0.05s.gif";
// import scootComing7 from "../../Gif-img/ScootComing/frame_07_delay-0.05s.gif";
import scootComing8 from "../../Gif-img/ScootComing/frame_08_delay-0.05s.gif";
import scootLeavingHeart0 from "../../Gif-img/ScootLeavingHeart/frame_00_delay-0.05s.gif";
import scootLeavingHeart1 from "../../Gif-img/ScootLeavingHeart/frame_01_delay-0.05s.gif";
import scootLeavingHeart10 from "../../Gif-img/ScootLeavingHeart/frame_10_delay-0.05s.gif";
import scootLeavingHeart11 from "../../Gif-img/ScootLeavingHeart/frame_11_delay-0.05s.gif";
import scootLeavingHeart12 from "../../Gif-img/ScootLeavingHeart/frame_12_delay-0.05s.gif";
import scootLeavingHeart13 from "../../Gif-img/ScootLeavingHeart/frame_13_delay-0.05s.gif";
import scootLeavingHeart14 from "../../Gif-img/ScootLeavingHeart/frame_14_delay-0.05s.gif";
import scootLeavingHeart15 from "../../Gif-img/ScootLeavingHeart/frame_15_delay-0.05s.gif";
import scootLeavingHeart16 from "../../Gif-img/ScootLeavingHeart/frame_16_delay-0.05s.gif";
import scootLeavingHeart17 from "../../Gif-img/ScootLeavingHeart/frame_17_delay-0.05s.gif";
import scootLeavingHeart18 from "../../Gif-img/ScootLeavingHeart/frame_18_delay-0.05s.gif";
import scootLeavingHeart19 from "../../Gif-img/ScootLeavingHeart/frame_19_delay-0.05s.gif";
import scootLeavingHeart2 from "../../Gif-img/ScootLeavingHeart/frame_02_delay-0.05s.gif";
import scootLeavingHeart20 from "../../Gif-img/ScootLeavingHeart/frame_20_delay-0.05s.gif";
import scootLeavingHeart21 from "../../Gif-img/ScootLeavingHeart/frame_21_delay-0.05s.gif";
import scootLeavingHeart22 from "../../Gif-img/ScootLeavingHeart/frame_22_delay-0.05s.gif";
import scootLeavingHeart23 from "../../Gif-img/ScootLeavingHeart/frame_23_delay-0.05s.gif";
import scootLeavingHeart24 from "../../Gif-img/ScootLeavingHeart/frame_24_delay-0.05s.gif";
import scootLeavingHeart25 from "../../Gif-img/ScootLeavingHeart/frame_25_delay-0.05s.gif";
import scootLeavingHeart26 from "../../Gif-img/ScootLeavingHeart/frame_26_delay-0.05s.gif";
import scootLeavingHeart27 from "../../Gif-img/ScootLeavingHeart/frame_27_delay-0.05s.gif";
import scootLeavingHeart28 from "../../Gif-img/ScootLeavingHeart/frame_28_delay-0.05s.gif";
import scootLeavingHeart29 from "../../Gif-img/ScootLeavingHeart/frame_29_delay-0.05s.gif";
import scootLeavingHeart3 from "../../Gif-img/ScootLeavingHeart/frame_03_delay-0.05s.gif";
import scootLeavingHeart30 from "../../Gif-img/ScootLeavingHeart/frame_30_delay-0.05s.gif";
import scootLeavingHeart31 from "../../Gif-img/ScootLeavingHeart/frame_31_delay-0.05s.gif";
import scootLeavingHeart32 from "../../Gif-img/ScootLeavingHeart/frame_32_delay-0.05s.gif";
import scootLeavingHeart33 from "../../Gif-img/ScootLeavingHeart/frame_33_delay-0.05s.gif";
import scootLeavingHeart34 from "../../Gif-img/ScootLeavingHeart/frame_34_delay-0.05s.gif";
import scootLeavingHeart35 from "../../Gif-img/ScootLeavingHeart/frame_35_delay-0.05s.gif";
import scootLeavingHeart36 from "../../Gif-img/ScootLeavingHeart/frame_36_delay-0.05s.gif";
import scootLeavingHeart37 from "../../Gif-img/ScootLeavingHeart/frame_37_delay-0.05s.gif";
import scootLeavingHeart38 from "../../Gif-img/ScootLeavingHeart/frame_38_delay-0.05s.gif";
import scootLeavingHeart39 from "../../Gif-img/ScootLeavingHeart/frame_39_delay-0.05s.gif";
import scootLeavingHeart4 from "../../Gif-img/ScootLeavingHeart/frame_04_delay-0.05s.gif";
import scootLeavingHeart40 from "../../Gif-img/ScootLeavingHeart/frame_40_delay-0.05s.gif";
import scootLeavingHeart41 from "../../Gif-img/ScootLeavingHeart/frame_41_delay-0.05s.gif";
import scootLeavingHeart42 from "../../Gif-img/ScootLeavingHeart/frame_42_delay-0.05s.gif";
import scootLeavingHeart43 from "../../Gif-img/ScootLeavingHeart/frame_43_delay-0.05s.gif";
import scootLeavingHeart44 from "../../Gif-img/ScootLeavingHeart/frame_44_delay-0.05s.gif";
import scootLeavingHeart45 from "../../Gif-img/ScootLeavingHeart/frame_45_delay-0.05s.gif";
import scootLeavingHeart46 from "../../Gif-img/ScootLeavingHeart/frame_46_delay-0.05s.gif";
import scootLeavingHeart47 from "../../Gif-img/ScootLeavingHeart/frame_47_delay-0.05s.gif";
import scootLeavingHeart48 from "../../Gif-img/ScootLeavingHeart/frame_48_delay-0.05s.gif";
import scootLeavingHeart49 from "../../Gif-img/ScootLeavingHeart/frame_49_delay-0.05s.gif";
import scootLeavingHeart5 from "../../Gif-img/ScootLeavingHeart/frame_05_delay-0.05s.gif";
import scootLeavingHeart50 from "../../Gif-img/ScootLeavingHeart/frame_50_delay-0.05s.gif";
import scootLeavingHeart51 from "../../Gif-img/ScootLeavingHeart/frame_51_delay-0.05s.gif";
import scootLeavingHeart52 from "../../Gif-img/ScootLeavingHeart/frame_52_delay-0.05s.gif";
import scootLeavingHeart53 from "../../Gif-img/ScootLeavingHeart/frame_53_delay-0.05s.gif";
import scootLeavingHeart54 from "../../Gif-img/ScootLeavingHeart/frame_54_delay-0.05s.gif";
import scootLeavingHeart55 from "../../Gif-img/ScootLeavingHeart/frame_55_delay-0.05s.gif";
import scootLeavingHeart56 from "../../Gif-img/ScootLeavingHeart/frame_56_delay-0.05s.gif";
import scootLeavingHeart57 from "../../Gif-img/ScootLeavingHeart/frame_57_delay-0.05s.gif";
import scootLeavingHeart58 from "../../Gif-img/ScootLeavingHeart/frame_58_delay-0.05s.gif";
import scootLeavingHeart59 from "../../Gif-img/ScootLeavingHeart/frame_59_delay-0.05s.gif";
import scootLeavingHeart6 from "../../Gif-img/ScootLeavingHeart/frame_06_delay-0.05s.gif";
import scootLeavingHeart7 from "../../Gif-img/ScootLeavingHeart/frame_07_delay-0.05s.gif";
import scootLeavingHeart8 from "../../Gif-img/ScootLeavingHeart/frame_08_delay-0.05s.gif";
import scootLeavingHeart9 from "../../Gif-img/ScootLeavingHeart/frame_09_delay-0.05s.gif";
import scootLeavingLemon0 from "../../Gif-img/ScootLeavingLemon/frame_00_delay-0.05s.gif";
import scootLeavingLemon1 from "../../Gif-img/ScootLeavingLemon/frame_01_delay-0.05s.gif";
import scootLeavingLemon10 from "../../Gif-img/ScootLeavingLemon/frame_10_delay-0.05s.gif";
import scootLeavingLemon11 from "../../Gif-img/ScootLeavingLemon/frame_11_delay-0.05s.gif";
import scootLeavingLemon12 from "../../Gif-img/ScootLeavingLemon/frame_12_delay-0.05s.gif";
import scootLeavingLemon13 from "../../Gif-img/ScootLeavingLemon/frame_13_delay-0.05s.gif";
import scootLeavingLemon14 from "../../Gif-img/ScootLeavingLemon/frame_14_delay-0.05s.gif";
import scootLeavingLemon15 from "../../Gif-img/ScootLeavingLemon/frame_15_delay-0.05s.gif";
import scootLeavingLemon16 from "../../Gif-img/ScootLeavingLemon/frame_16_delay-0.05s.gif";
import scootLeavingLemon17 from "../../Gif-img/ScootLeavingLemon/frame_17_delay-0.05s.gif";
import scootLeavingLemon18 from "../../Gif-img/ScootLeavingLemon/frame_18_delay-0.05s.gif";
import scootLeavingLemon19 from "../../Gif-img/ScootLeavingLemon/frame_19_delay-0.05s.gif";
import scootLeavingLemon2 from "../../Gif-img/ScootLeavingLemon/frame_02_delay-0.05s.gif";
import scootLeavingLemon20 from "../../Gif-img/ScootLeavingLemon/frame_20_delay-0.05s.gif";
import scootLeavingLemon21 from "../../Gif-img/ScootLeavingLemon/frame_21_delay-0.05s.gif";
import scootLeavingLemon22 from "../../Gif-img/ScootLeavingLemon/frame_22_delay-0.05s.gif";
import scootLeavingLemon23 from "../../Gif-img/ScootLeavingLemon/frame_23_delay-0.05s.gif";
import scootLeavingLemon24 from "../../Gif-img/ScootLeavingLemon/frame_24_delay-0.05s.gif";
import scootLeavingLemon25 from "../../Gif-img/ScootLeavingLemon/frame_25_delay-0.05s.gif";
import scootLeavingLemon26 from "../../Gif-img/ScootLeavingLemon/frame_26_delay-0.05s.gif";
import scootLeavingLemon27 from "../../Gif-img/ScootLeavingLemon/frame_27_delay-0.05s.gif";
import scootLeavingLemon28 from "../../Gif-img/ScootLeavingLemon/frame_28_delay-0.05s.gif";
import scootLeavingLemon29 from "../../Gif-img/ScootLeavingLemon/frame_29_delay-0.05s.gif";
import scootLeavingLemon3 from "../../Gif-img/ScootLeavingLemon/frame_03_delay-0.05s.gif";
import scootLeavingLemon30 from "../../Gif-img/ScootLeavingLemon/frame_30_delay-0.05s.gif";
import scootLeavingLemon31 from "../../Gif-img/ScootLeavingLemon/frame_31_delay-0.05s.gif";
import scootLeavingLemon32 from "../../Gif-img/ScootLeavingLemon/frame_32_delay-0.05s.gif";
import scootLeavingLemon33 from "../../Gif-img/ScootLeavingLemon/frame_33_delay-0.05s.gif";
import scootLeavingLemon34 from "../../Gif-img/ScootLeavingLemon/frame_34_delay-0.05s.gif";
import scootLeavingLemon35 from "../../Gif-img/ScootLeavingLemon/frame_35_delay-0.05s.gif";
import scootLeavingLemon36 from "../../Gif-img/ScootLeavingLemon/frame_36_delay-0.05s.gif";
import scootLeavingLemon37 from "../../Gif-img/ScootLeavingLemon/frame_37_delay-0.05s.gif";
import scootLeavingLemon38 from "../../Gif-img/ScootLeavingLemon/frame_38_delay-0.05s.gif";
import scootLeavingLemon39 from "../../Gif-img/ScootLeavingLemon/frame_39_delay-0.05s.gif";
import scootLeavingLemon4 from "../../Gif-img/ScootLeavingLemon/frame_04_delay-0.05s.gif";
import scootLeavingLemon40 from "../../Gif-img/ScootLeavingLemon/frame_40_delay-0.05s.gif";
import scootLeavingLemon41 from "../../Gif-img/ScootLeavingLemon/frame_41_delay-0.05s.gif";
import scootLeavingLemon42 from "../../Gif-img/ScootLeavingLemon/frame_42_delay-0.05s.gif";
import scootLeavingLemon43 from "../../Gif-img/ScootLeavingLemon/frame_43_delay-0.05s.gif";
import scootLeavingLemon44 from "../../Gif-img/ScootLeavingLemon/frame_44_delay-0.05s.gif";
import scootLeavingLemon45 from "../../Gif-img/ScootLeavingLemon/frame_45_delay-0.05s.gif";
import scootLeavingLemon46 from "../../Gif-img/ScootLeavingLemon/frame_46_delay-0.05s.gif";
import scootLeavingLemon47 from "../../Gif-img/ScootLeavingLemon/frame_47_delay-0.05s.gif";
import scootLeavingLemon48 from "../../Gif-img/ScootLeavingLemon/frame_48_delay-0.05s.gif";
import scootLeavingLemon49 from "../../Gif-img/ScootLeavingLemon/frame_49_delay-0.05s.gif";
import scootLeavingLemon5 from "../../Gif-img/ScootLeavingLemon/frame_05_delay-0.05s.gif";
import scootLeavingLemon50 from "../../Gif-img/ScootLeavingLemon/frame_50_delay-0.05s.gif";
import scootLeavingLemon51 from "../../Gif-img/ScootLeavingLemon/frame_51_delay-0.05s.gif";
import scootLeavingLemon52 from "../../Gif-img/ScootLeavingLemon/frame_52_delay-0.05s.gif";
import scootLeavingLemon53 from "../../Gif-img/ScootLeavingLemon/frame_53_delay-0.05s.gif";
import scootLeavingLemon54 from "../../Gif-img/ScootLeavingLemon/frame_54_delay-0.05s.gif";
import scootLeavingLemon55 from "../../Gif-img/ScootLeavingLemon/frame_55_delay-0.05s.gif";
import scootLeavingLemon56 from "../../Gif-img/ScootLeavingLemon/frame_56_delay-0.05s.gif";
import scootLeavingLemon57 from "../../Gif-img/ScootLeavingLemon/frame_57_delay-0.05s.gif";
import scootLeavingLemon58 from "../../Gif-img/ScootLeavingLemon/frame_58_delay-0.05s.gif";
import scootLeavingLemon59 from "../../Gif-img/ScootLeavingLemon/frame_59_delay-0.05s.gif";
import scootLeavingLemon6 from "../../Gif-img/ScootLeavingLemon/frame_06_delay-0.05s.gif";
import scootLeavingLemon7 from "../../Gif-img/ScootLeavingLemon/frame_07_delay-0.05s.gif";
import scootLeavingLemon8 from "../../Gif-img/ScootLeavingLemon/frame_08_delay-0.05s.gif";
import scootLeavingLemon9 from "../../Gif-img/ScootLeavingLemon/frame_09_delay-0.05s.gif";
import scootLeavingTomato0 from "../../Gif-img/ScootLeavingTomato/frame_00_delay-0.05s.gif";
import scootLeavingTomato1 from "../../Gif-img/ScootLeavingTomato/frame_01_delay-0.05s.gif";
import scootLeavingTomato10 from "../../Gif-img/ScootLeavingTomato/frame_10_delay-0.05s.gif";
import scootLeavingTomato11 from "../../Gif-img/ScootLeavingTomato/frame_11_delay-0.05s.gif";
import scootLeavingTomato12 from "../../Gif-img/ScootLeavingTomato/frame_12_delay-0.05s.gif";
import scootLeavingTomato13 from "../../Gif-img/ScootLeavingTomato/frame_13_delay-0.05s.gif";
import scootLeavingTomato14 from "../../Gif-img/ScootLeavingTomato/frame_14_delay-0.05s.gif";
import scootLeavingTomato15 from "../../Gif-img/ScootLeavingTomato/frame_15_delay-0.05s.gif";
import scootLeavingTomato16 from "../../Gif-img/ScootLeavingTomato/frame_16_delay-0.05s.gif";
import scootLeavingTomato17 from "../../Gif-img/ScootLeavingTomato/frame_17_delay-0.05s.gif";
import scootLeavingTomato18 from "../../Gif-img/ScootLeavingTomato/frame_18_delay-0.05s.gif";
import scootLeavingTomato19 from "../../Gif-img/ScootLeavingTomato/frame_19_delay-0.05s.gif";
import scootLeavingTomato2 from "../../Gif-img/ScootLeavingTomato/frame_02_delay-0.05s.gif";
import scootLeavingTomato20 from "../../Gif-img/ScootLeavingTomato/frame_20_delay-0.05s.gif";
import scootLeavingTomato21 from "../../Gif-img/ScootLeavingTomato/frame_21_delay-0.05s.gif";
import scootLeavingTomato22 from "../../Gif-img/ScootLeavingTomato/frame_22_delay-0.05s.gif";
import scootLeavingTomato23 from "../../Gif-img/ScootLeavingTomato/frame_23_delay-0.05s.gif";
import scootLeavingTomato24 from "../../Gif-img/ScootLeavingTomato/frame_24_delay-0.05s.gif";
import scootLeavingTomato25 from "../../Gif-img/ScootLeavingTomato/frame_25_delay-0.05s.gif";
import scootLeavingTomato26 from "../../Gif-img/ScootLeavingTomato/frame_26_delay-0.05s.gif";
import scootLeavingTomato27 from "../../Gif-img/ScootLeavingTomato/frame_27_delay-0.05s.gif";
import scootLeavingTomato28 from "../../Gif-img/ScootLeavingTomato/frame_28_delay-0.05s.gif";
import scootLeavingTomato29 from "../../Gif-img/ScootLeavingTomato/frame_29_delay-0.05s.gif";
import scootLeavingTomato3 from "../../Gif-img/ScootLeavingTomato/frame_03_delay-0.05s.gif";
import scootLeavingTomato30 from "../../Gif-img/ScootLeavingTomato/frame_30_delay-0.05s.gif";
import scootLeavingTomato31 from "../../Gif-img/ScootLeavingTomato/frame_31_delay-0.05s.gif";
import scootLeavingTomato32 from "../../Gif-img/ScootLeavingTomato/frame_32_delay-0.05s.gif";
import scootLeavingTomato33 from "../../Gif-img/ScootLeavingTomato/frame_33_delay-0.05s.gif";
import scootLeavingTomato34 from "../../Gif-img/ScootLeavingTomato/frame_34_delay-0.05s.gif";
import scootLeavingTomato35 from "../../Gif-img/ScootLeavingTomato/frame_35_delay-0.05s.gif";
import scootLeavingTomato36 from "../../Gif-img/ScootLeavingTomato/frame_36_delay-0.05s.gif";
import scootLeavingTomato37 from "../../Gif-img/ScootLeavingTomato/frame_37_delay-0.05s.gif";
import scootLeavingTomato38 from "../../Gif-img/ScootLeavingTomato/frame_38_delay-0.05s.gif";
import scootLeavingTomato39 from "../../Gif-img/ScootLeavingTomato/frame_39_delay-0.05s.gif";
import scootLeavingTomato4 from "../../Gif-img/ScootLeavingTomato/frame_04_delay-0.05s.gif";
import scootLeavingTomato40 from "../../Gif-img/ScootLeavingTomato/frame_40_delay-0.05s.gif";
import scootLeavingTomato41 from "../../Gif-img/ScootLeavingTomato/frame_41_delay-0.05s.gif";
import scootLeavingTomato42 from "../../Gif-img/ScootLeavingTomato/frame_42_delay-0.05s.gif";
import scootLeavingTomato43 from "../../Gif-img/ScootLeavingTomato/frame_43_delay-0.05s.gif";
import scootLeavingTomato44 from "../../Gif-img/ScootLeavingTomato/frame_44_delay-0.05s.gif";
import scootLeavingTomato45 from "../../Gif-img/ScootLeavingTomato/frame_45_delay-0.05s.gif";
import scootLeavingTomato46 from "../../Gif-img/ScootLeavingTomato/frame_46_delay-0.05s.gif";
import scootLeavingTomato47 from "../../Gif-img/ScootLeavingTomato/frame_47_delay-0.05s.gif";
import scootLeavingTomato48 from "../../Gif-img/ScootLeavingTomato/frame_48_delay-0.05s.gif";
import scootLeavingTomato49 from "../../Gif-img/ScootLeavingTomato/frame_49_delay-0.05s.gif";
import scootLeavingTomato5 from "../../Gif-img/ScootLeavingTomato/frame_05_delay-0.05s.gif";
import scootLeavingTomato50 from "../../Gif-img/ScootLeavingTomato/frame_50_delay-0.05s.gif";
import scootLeavingTomato51 from "../../Gif-img/ScootLeavingTomato/frame_51_delay-0.05s.gif";
import scootLeavingTomato52 from "../../Gif-img/ScootLeavingTomato/frame_52_delay-0.05s.gif";
import scootLeavingTomato53 from "../../Gif-img/ScootLeavingTomato/frame_53_delay-0.05s.gif";
import scootLeavingTomato54 from "../../Gif-img/ScootLeavingTomato/frame_54_delay-0.05s.gif";
import scootLeavingTomato55 from "../../Gif-img/ScootLeavingTomato/frame_55_delay-0.05s.gif";
import scootLeavingTomato56 from "../../Gif-img/ScootLeavingTomato/frame_56_delay-0.05s.gif";
import scootLeavingTomato57 from "../../Gif-img/ScootLeavingTomato/frame_57_delay-0.05s.gif";
import scootLeavingTomato58 from "../../Gif-img/ScootLeavingTomato/frame_58_delay-0.05s.gif";
import scootLeavingTomato59 from "../../Gif-img/ScootLeavingTomato/frame_59_delay-0.05s.gif";
import scootLeavingTomato6 from "../../Gif-img/ScootLeavingTomato/frame_06_delay-0.05s.gif";
import scootLeavingTomato7 from "../../Gif-img/ScootLeavingTomato/frame_07_delay-0.05s.gif";
import scootLeavingTomato8 from "../../Gif-img/ScootLeavingTomato/frame_08_delay-0.05s.gif";
import scootLeavingTomato9 from "../../Gif-img/ScootLeavingTomato/frame_09_delay-0.05s.gif";
import scootThrowHeart0 from "../../Gif-img/ScootThrowHeart/frame_00_delay-0.05s.gif";
import scootThrowHeart1 from "../../Gif-img/ScootThrowHeart/frame_01_delay-0.05s.gif";
import scootThrowHeart10 from "../../Gif-img/ScootThrowHeart/frame_10_delay-0.05s.gif";
import scootThrowHeart11 from "../../Gif-img/ScootThrowHeart/frame_11_delay-0.05s.gif";
import scootThrowHeart12 from "../../Gif-img/ScootThrowHeart/frame_12_delay-0.05s.gif";
import scootThrowHeart13 from "../../Gif-img/ScootThrowHeart/frame_13_delay-0.05s.gif";
import scootThrowHeart14 from "../../Gif-img/ScootThrowHeart/frame_14_delay-0.05s.gif";
import scootThrowHeart15 from "../../Gif-img/ScootThrowHeart/frame_15_delay-0.05s.gif";
import scootThrowHeart16 from "../../Gif-img/ScootThrowHeart/frame_16_delay-0.05s.gif";
import scootThrowHeart17 from "../../Gif-img/ScootThrowHeart/frame_17_delay-0.05s.gif";
import scootThrowHeart18 from "../../Gif-img/ScootThrowHeart/frame_18_delay-0.05s.gif";
import scootThrowHeart19 from "../../Gif-img/ScootThrowHeart/frame_19_delay-0.05s.gif";
import scootThrowHeart2 from "../../Gif-img/ScootThrowHeart/frame_02_delay-0.05s.gif";
import scootThrowHeart3 from "../../Gif-img/ScootThrowHeart/frame_03_delay-0.05s.gif";
import scootThrowHeart4 from "../../Gif-img/ScootThrowHeart/frame_04_delay-0.05s.gif";
import scootThrowHeart5 from "../../Gif-img/ScootThrowHeart/frame_05_delay-0.05s.gif";
import scootThrowHeart6 from "../../Gif-img/ScootThrowHeart/frame_06_delay-0.05s.gif";
import scootThrowHeart7 from "../../Gif-img/ScootThrowHeart/frame_07_delay-0.05s.gif";
import scootThrowHeart8 from "../../Gif-img/ScootThrowHeart/frame_08_delay-0.05s.gif";
import scootThrowHeart9 from "../../Gif-img/ScootThrowHeart/frame_09_delay-0.05s.gif";
import scootThrowLemon0 from "../../Gif-img/ScootThrowLemon/frame_00_delay-0.05s.gif";
import scootThrowLemon1 from "../../Gif-img/ScootThrowLemon/frame_01_delay-0.05s.gif";
import scootThrowLemon10 from "../../Gif-img/ScootThrowLemon/frame_10_delay-0.05s.gif";
import scootThrowLemon11 from "../../Gif-img/ScootThrowLemon/frame_11_delay-0.05s.gif";
import scootThrowLemon12 from "../../Gif-img/ScootThrowLemon/frame_12_delay-0.05s.gif";
import scootThrowLemon13 from "../../Gif-img/ScootThrowLemon/frame_13_delay-0.05s.gif";
import scootThrowLemon14 from "../../Gif-img/ScootThrowLemon/frame_14_delay-0.05s.gif";
import scootThrowLemon15 from "../../Gif-img/ScootThrowLemon/frame_15_delay-0.05s.gif";
import scootThrowLemon16 from "../../Gif-img/ScootThrowLemon/frame_16_delay-0.05s.gif";
import scootThrowLemon17 from "../../Gif-img/ScootThrowLemon/frame_17_delay-0.05s.gif";
import scootThrowLemon18 from "../../Gif-img/ScootThrowLemon/frame_18_delay-0.05s.gif";
import scootThrowLemon19 from "../../Gif-img/ScootThrowLemon/frame_19_delay-0.05s.gif";
import scootThrowLemon2 from "../../Gif-img/ScootThrowLemon/frame_02_delay-0.05s.gif";
import scootThrowLemon3 from "../../Gif-img/ScootThrowLemon/frame_03_delay-0.05s.gif";
import scootThrowLemon4 from "../../Gif-img/ScootThrowLemon/frame_04_delay-0.05s.gif";
import scootThrowLemon5 from "../../Gif-img/ScootThrowLemon/frame_05_delay-0.05s.gif";
import scootThrowLemon6 from "../../Gif-img/ScootThrowLemon/frame_06_delay-0.05s.gif";
import scootThrowLemon7 from "../../Gif-img/ScootThrowLemon/frame_07_delay-0.05s.gif";
import scootThrowLemon8 from "../../Gif-img/ScootThrowLemon/frame_08_delay-0.05s.gif";
import scootThrowLemon9 from "../../Gif-img/ScootThrowLemon/frame_09_delay-0.05s.gif";
import scootThrowTomato0 from "../../Gif-img/ScootThrowTomato/frame_00_delay-0.05s.gif";
import scootThrowTomato1 from "../../Gif-img/ScootThrowTomato/frame_01_delay-0.05s.gif";
import scootThrowTomato10 from "../../Gif-img/ScootThrowTomato/frame_10_delay-0.05s.gif";
import scootThrowTomato11 from "../../Gif-img/ScootThrowTomato/frame_11_delay-0.05s.gif";
import scootThrowTomato12 from "../../Gif-img/ScootThrowTomato/frame_12_delay-0.05s.gif";
import scootThrowTomato13 from "../../Gif-img/ScootThrowTomato/frame_13_delay-0.05s.gif";
import scootThrowTomato14 from "../../Gif-img/ScootThrowTomato/frame_14_delay-0.05s.gif";
import scootThrowTomato15 from "../../Gif-img/ScootThrowTomato/frame_15_delay-0.05s.gif";
import scootThrowTomato16 from "../../Gif-img/ScootThrowTomato/frame_16_delay-0.05s.gif";
import scootThrowTomato17 from "../../Gif-img/ScootThrowTomato/frame_17_delay-0.05s.gif";
import scootThrowTomato18 from "../../Gif-img/ScootThrowTomato/frame_18_delay-0.05s.gif";
import scootThrowTomato19 from "../../Gif-img/ScootThrowTomato/frame_19_delay-0.05s.gif";
import scootThrowTomato2 from "../../Gif-img/ScootThrowTomato/frame_02_delay-0.05s.gif";
import scootThrowTomato3 from "../../Gif-img/ScootThrowTomato/frame_03_delay-0.05s.gif";
import scootThrowTomato4 from "../../Gif-img/ScootThrowTomato/frame_04_delay-0.05s.gif";
import scootThrowTomato5 from "../../Gif-img/ScootThrowTomato/frame_05_delay-0.05s.gif";
import scootThrowTomato6 from "../../Gif-img/ScootThrowTomato/frame_06_delay-0.05s.gif";
import scootThrowTomato7 from "../../Gif-img/ScootThrowTomato/frame_07_delay-0.05s.gif";
import scootThrowTomato8 from "../../Gif-img/ScootThrowTomato/frame_08_delay-0.05s.gif";
import scootThrowTomato9 from "../../Gif-img/ScootThrowTomato/frame_09_delay-0.05s.gif";
import styled from "styled-components";
import { useState } from "react";

// import bg56 from "../../Gif-img/Background/frame_56_delay-0.05s.gif";
// import bg57 from "../../Gif-img/Background/frame_57_delay-0.05s.gif";
// import bg58 from "../../Gif-img/Background/frame_58_delay-0.05s.gif";
// import bg59 from "../../Gif-img/Background/frame_59_delay-0.05s.gif";


// import scootComing35 from "../../Gif-img/ScootComing/frame_35_delay-0.05s.gif";
















require("../../Firebase.js");
const analytics = getAnalytics();

const jetLeavingTomatoLayers = [
  jetLeavingTomato0,
  jetLeavingTomato1,
  jetLeavingTomato2,
  jetLeavingTomato3,
  jetLeavingTomato4,
  jetLeavingTomato5,
  jetLeavingTomato6,
  jetLeavingTomato7,
  jetLeavingTomato8,
  jetLeavingTomato9,
  jetLeavingTomato10,
  jetLeavingTomato11,
  jetLeavingTomato12,
  jetLeavingTomato13,
  jetLeavingTomato14,
  jetLeavingTomato15,
  jetLeavingTomato16,
  jetLeavingTomato17,
  jetLeavingTomato18,
  jetLeavingTomato19,
  jetLeavingTomato20,
  jetLeavingTomato21,
  jetLeavingTomato22,
  jetLeavingTomato23,
  jetLeavingTomato24,
  jetLeavingTomato25,
  jetLeavingTomato26,
  jetLeavingTomato27,
  jetLeavingTomato28,
];
const jetLeavingLemonLayers = [
  jetLeavingLemon0,
  jetLeavingLemon1,
  jetLeavingLemon2,
  jetLeavingLemon3,
  jetLeavingLemon4,
  jetLeavingLemon5,
  jetLeavingLemon6,
  jetLeavingLemon7,
  jetLeavingLemon8,
  jetLeavingLemon9,
  jetLeavingLemon10,
  jetLeavingLemon11,
  jetLeavingLemon12,
  jetLeavingLemon13,
  jetLeavingLemon14,
  jetLeavingLemon15,
  jetLeavingLemon16,
  jetLeavingLemon17,
  jetLeavingLemon18,
  jetLeavingLemon19,
  jetLeavingLemon20,
  jetLeavingLemon21,
  jetLeavingLemon22,
  jetLeavingLemon23,
  jetLeavingLemon24,
  jetLeavingLemon25,
  jetLeavingLemon26,
  jetLeavingLemon27,
  jetLeavingLemon28,
];
const jetLeavingHeartLayers = [
  jetLeavingHeart0,
  jetLeavingHeart1,
  jetLeavingHeart2,
  jetLeavingHeart3,
  jetLeavingHeart4,
  jetLeavingHeart5,
  jetLeavingHeart6,
  jetLeavingHeart7,
  jetLeavingHeart8,
  jetLeavingHeart9,
  jetLeavingHeart10,
  jetLeavingHeart11,
  jetLeavingHeart12,
  jetLeavingHeart13,
  jetLeavingHeart14,
  jetLeavingHeart15,
  jetLeavingHeart16,
  jetLeavingHeart17,
  jetLeavingHeart18,
  jetLeavingHeart19,
  jetLeavingHeart20,
  jetLeavingHeart21,
  jetLeavingHeart22,
  jetLeavingHeart23,
  jetLeavingHeart24,
  jetLeavingHeart25,
  jetLeavingHeart26,
  jetLeavingHeart27,
  jetLeavingHeart28,
];
const scootLeavingTomatoLayers = [
  scootLeavingTomato0,
  scootLeavingTomato1,
  scootLeavingTomato2,
  scootLeavingTomato3,
  scootLeavingTomato4,
  scootLeavingTomato5,
  scootLeavingTomato6,
  scootLeavingTomato7,
  scootLeavingTomato8,
  scootLeavingTomato9,
  scootLeavingTomato10,
  scootLeavingTomato11,
  scootLeavingTomato12,
  scootLeavingTomato13,
  scootLeavingTomato14,
  scootLeavingTomato15,
  scootLeavingTomato16,
  scootLeavingTomato17,
  scootLeavingTomato18,
  scootLeavingTomato19,
  scootLeavingTomato20,
  scootLeavingTomato21,
  scootLeavingTomato22,
  scootLeavingTomato23,
  scootLeavingTomato24,
  scootLeavingTomato25,
  scootLeavingTomato26,
  scootLeavingTomato27,
  scootLeavingTomato28,
  scootLeavingTomato29,
  scootLeavingTomato30,
  scootLeavingTomato31,
  scootLeavingTomato32,
  scootLeavingTomato33,
  scootLeavingTomato34,
  scootLeavingTomato35,
  scootLeavingTomato36,
  scootLeavingTomato37,
  scootLeavingTomato38,
  scootLeavingTomato39,
  scootLeavingTomato40,
  scootLeavingTomato41,
  scootLeavingTomato42,
  scootLeavingTomato43,
  scootLeavingTomato44,
  scootLeavingTomato45,
  scootLeavingTomato46,
  scootLeavingTomato47,
  scootLeavingTomato48,
  scootLeavingTomato49,
  scootLeavingTomato50,
  scootLeavingTomato51,
  scootLeavingTomato52,
  scootLeavingTomato53,
  scootLeavingTomato54,
  scootLeavingTomato55,
  scootLeavingTomato56,
  scootLeavingTomato57,
  scootLeavingTomato58,
  scootLeavingTomato59,
];
const scootLeavingLemonLayers = [
  scootLeavingLemon0,
  scootLeavingLemon1,
  scootLeavingLemon2,
  scootLeavingLemon3,
  scootLeavingLemon4,
  scootLeavingLemon5,
  scootLeavingLemon6,
  scootLeavingLemon7,
  scootLeavingLemon8,
  scootLeavingLemon9,
  scootLeavingLemon10,
  scootLeavingLemon11,
  scootLeavingLemon12,
  scootLeavingLemon13,
  scootLeavingLemon14,
  scootLeavingLemon15,
  scootLeavingLemon16,
  scootLeavingLemon17,
  scootLeavingLemon18,
  scootLeavingLemon19,
  scootLeavingLemon20,
  scootLeavingLemon21,
  scootLeavingLemon22,
  scootLeavingLemon23,
  scootLeavingLemon24,
  scootLeavingLemon25,
  scootLeavingLemon26,
  scootLeavingLemon27,
  scootLeavingLemon28,
  scootLeavingLemon29,
  scootLeavingLemon30,
  scootLeavingLemon31,
  scootLeavingLemon32,
  scootLeavingLemon33,
  scootLeavingLemon34,
  scootLeavingLemon35,
  scootLeavingLemon36,
  scootLeavingLemon37,
  scootLeavingLemon38,
  scootLeavingLemon39,
  scootLeavingLemon40,
  scootLeavingLemon41,
  scootLeavingLemon42,
  scootLeavingLemon43,
  scootLeavingLemon44,
  scootLeavingLemon45,
  scootLeavingLemon46,
  scootLeavingLemon47,
  scootLeavingLemon48,
  scootLeavingLemon49,
  scootLeavingLemon50,
  scootLeavingLemon51,
  scootLeavingLemon52,
  scootLeavingLemon53,
  scootLeavingLemon54,
  scootLeavingLemon55,
  scootLeavingLemon56,
  scootLeavingLemon57,
  scootLeavingLemon58,
  scootLeavingLemon59,
];
const scootLeavingHeartLayers = [
  scootLeavingHeart0,
  scootLeavingHeart1,
  scootLeavingHeart2,
  scootLeavingHeart3,
  scootLeavingHeart4,
  scootLeavingHeart5,
  scootLeavingHeart6,
  scootLeavingHeart7,
  scootLeavingHeart8,
  scootLeavingHeart9,
  scootLeavingHeart10,
  scootLeavingHeart11,
  scootLeavingHeart12,
  scootLeavingHeart13,
  scootLeavingHeart14,
  scootLeavingHeart15,
  scootLeavingHeart16,
  scootLeavingHeart17,
  scootLeavingHeart18,
  scootLeavingHeart19,
  scootLeavingHeart20,
  scootLeavingHeart21,
  scootLeavingHeart22,
  scootLeavingHeart23,
  scootLeavingHeart24,
  scootLeavingHeart25,
  scootLeavingHeart26,
  scootLeavingHeart27,
  scootLeavingHeart28,
  scootLeavingHeart29,
  scootLeavingHeart30,
  scootLeavingHeart31,
  scootLeavingHeart32,
  scootLeavingHeart33,
  scootLeavingHeart34,
  scootLeavingHeart35,
  scootLeavingHeart36,
  scootLeavingHeart37,
  scootLeavingHeart38,
  scootLeavingHeart39,
  scootLeavingHeart40,
  scootLeavingHeart41,
  scootLeavingHeart42,
  scootLeavingHeart43,
  scootLeavingHeart44,
  scootLeavingHeart45,
  scootLeavingHeart46,
  scootLeavingHeart47,
  scootLeavingHeart48,
  scootLeavingHeart49,
  scootLeavingHeart50,
  scootLeavingHeart51,
  scootLeavingHeart52,
  scootLeavingHeart53,
  scootLeavingHeart54,
  scootLeavingHeart55,
  scootLeavingHeart56,
  scootLeavingHeart57,
  scootLeavingHeart58,
  scootLeavingHeart59,
];

///////////

const jetThrowTomatoLayers = [
  jetThrowTomato0,
  jetThrowTomato1,
  jetThrowTomato2,
  jetThrowTomato3,
  jetThrowTomato4,
  jetThrowTomato5,
  jetThrowTomato6,
  jetThrowTomato7,
  jetThrowTomato8,
  jetThrowTomato9,
  jetThrowTomato10,
  jetThrowTomato11,
  jetThrowTomato12,
  jetThrowTomato13,
  jetThrowTomato14,
  jetThrowTomato15,
  jetThrowTomato16,
  jetThrowTomato17,
  jetThrowTomato18,
  jetThrowTomato19,
  jetThrowTomato20,
  jetThrowTomato21,
];
const jetThrowLemonLayers = [
  jetThrowLemon0,
  jetThrowLemon1,
  jetThrowLemon2,
  jetThrowLemon3,
  jetThrowLemon4,
  jetThrowLemon5,
  jetThrowLemon6,
  jetThrowLemon7,
  jetThrowLemon8,
  jetThrowLemon9,
  jetThrowLemon10,
  jetThrowLemon11,
  jetThrowLemon12,
  jetThrowLemon13,
  jetThrowLemon14,
  jetThrowLemon15,
  jetThrowLemon16,
  jetThrowLemon17,
  jetThrowLemon18,
  jetThrowLemon19,
  jetThrowLemon20,
  jetThrowLemon21,
];
const jetThrowHeartLayers = [
  jetThrowHeart0,
  jetThrowHeart1,
  jetThrowHeart2,
  jetThrowHeart3,
  jetThrowHeart4,
  jetThrowHeart5,
  jetThrowHeart6,
  jetThrowHeart7,
  jetThrowHeart8,
  jetThrowHeart9,
  jetThrowHeart10,
  jetThrowHeart11,
  jetThrowHeart12,
  jetThrowHeart13,
  jetThrowHeart14,
  jetThrowHeart15,
  jetThrowHeart16,
  jetThrowHeart17,
  jetThrowHeart18,
  jetThrowHeart19,
  jetThrowHeart20,
  jetThrowHeart21,
];
const scootThrowTomatoLayers = [
  scootThrowTomato0,
  scootThrowTomato1,
  scootThrowTomato2,
  scootThrowTomato3,
  scootThrowTomato4,
  scootThrowTomato5,
  scootThrowTomato6,
  scootThrowTomato7,
  scootThrowTomato8,
  scootThrowTomato9,
  scootThrowTomato10,
  scootThrowTomato11,
  scootThrowTomato12,
  scootThrowTomato13,
  scootThrowTomato14,
  scootThrowTomato15,
  scootThrowTomato16,
  scootThrowTomato17,
  scootThrowTomato18,
  scootThrowTomato19,
];
const scootThrowLemonLayers = [
  scootThrowLemon0,
  scootThrowLemon1,
  scootThrowLemon2,
  scootThrowLemon3,
  scootThrowLemon4,
  scootThrowLemon5,
  scootThrowLemon6,
  scootThrowLemon7,
  scootThrowLemon8,
  scootThrowLemon9,
  scootThrowLemon10,
  scootThrowLemon11,
  scootThrowLemon12,
  scootThrowLemon13,
  scootThrowLemon14,
  scootThrowLemon15,
  scootThrowLemon16,
  scootThrowLemon17,
  scootThrowLemon18,
  scootThrowLemon19,
];
const scootThrowHeartLayers = [
  scootThrowHeart0,
  scootThrowHeart1,
  scootThrowHeart2,
  scootThrowHeart3,
  scootThrowHeart4,
  scootThrowHeart5,
  scootThrowHeart6,
  scootThrowHeart7,
  scootThrowHeart8,
  scootThrowHeart9,
  scootThrowHeart10,
  scootThrowHeart11,
  scootThrowHeart12,
  scootThrowHeart13,
  scootThrowHeart14,
  scootThrowHeart15,
  scootThrowHeart16,
  scootThrowHeart17,
  scootThrowHeart18,
  scootThrowHeart19,
];

const Wrapper = styled.div`
  width: 95%;
  color: white;
  margin-top: 50px;
  font-family: "minecraft";
  letter-spacing: 1px;
`;

const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
`;

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #282741;
  padding: 30px;
  border-radius: 50px;

  @media (max-width: 768px) {
    padding: 15px;
    border-radius: 15px;
  }
`;

const Title = styled.div`
  font-size: 3em;
  font-weight: 800;
  font-family: "Minecraft";
  text-align: center;

  @media (max-width: 768px) {
    font-size: 2em;
  }
`;

const StepTitle = styled.div`
  align-self: flex-start;
  font-size: 20px;
  font-weight: 800;
  font-family: "Minecraft";

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const BackButton = styled.div`
  cursor: pointer;
  font-size: 20px;
  font-weight: 800;
  font-family: "Minecraft";

  :hover {
    opacity: 0.5;
  }

  transition: 0.1s;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const SubTitle = styled.div`
  font-size: 22px;
  margin-bottom: 15px;
  margin-top: 10px;
  opacity: 0.7;
  font-family: "Minecraft";
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

const StepTitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Stepper = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 23px;
  font-weight: 600;
  font-family: "Minecraft";
`;

const ScamGiff = styled.img`
  width: 100%;
  max-width: 512px;
`;

const StepLabel = styled.div`
  opacity: ${(props) => (props.isActive === true ? "1" : "0.1")};
  transition: 0.1s ease-in-out;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const GenerateButton = styled.button`
  background: #c2fd65;
  color: #4eae45;
  padding: 20px 50px;
  font-size: 30px;
  font-weight: 800;
  border-radius: 100px;
  border: none;
  cursor: pointer;
  outline: inherit;
  margin: 50px 0;
  font-family: "minecraft";

  @media (max-width: 768px) {
    font-size: 16px;
    padding: 10px 30px;
  }
`;

const DownloadButton = styled.a`
  text-decoration: none;
  background: #c2fd65;
  color: #4eae45;
  padding: 20px 50px;
  font-size: 30px;
  font-weight: 800;
  border-radius: 100px;
  border: none;
  cursor: pointer;
  outline: inherit;
  margin: 50px 0;
  font-family: "minecraft";

  @media (max-width: 768px) {
    font-size: 16px;
    padding: 10px 30px;
  }
`;

const GeneratorWrapper = styled.div`
  padding: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const GeneratingText = styled.div`
  width: 100%;
  margin: 20px 0;
`;

const UserManual = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [vehicle, setVehicle] = useState(-1);
  const [weapon, setWeapon] = useState(-1);
  const [imageScam, setImageScam] = useState("");
  const [scamGif, setScamGif] = useState(undefined);
  const [generatorProgress, setGeneratorProgress] = useState(0);
  const [isGenerating, setIsGenerating] = useState(false);

  const onVehicleClicked = (value) => {
    setVehicle(value);
    setCurrentStep(currentStep + 1);
  };

  const onWeaponClicked = (value) => {
    setWeapon(value);
    setCurrentStep(currentStep + 1);
  };

  const onScamChoose = (value) => {
    setImageScam(value);
    setCurrentStep(currentStep + 1);
  };

  const getLeavingLayer = (vehicle, weapon, index) => {
    if (vehicle === 0) {
      if (weapon === 0) {
        return jetLeavingTomatoLayers[index];
      }
      if (weapon === 1) {
        return jetLeavingLemonLayers[index];
      }
      if (weapon === 2) {
        return jetLeavingHeartLayers[index];
      }
    } else if (vehicle === 1) {
      if (weapon === 0) {
        return scootLeavingTomatoLayers[index];
      }
      if (weapon === 1) {
        return scootLeavingLemonLayers[index];
      }
      if (weapon === 2) {
        return scootLeavingHeartLayers[index];
      }
    }
  };

  const getThrowLayer = (vehicle, weapon, index) => {
    if (vehicle === 0) {
      if (weapon === 0) {
        return jetThrowTomatoLayers[index];
      }
      if (weapon === 1) {
        return jetThrowLemonLayers[index];
      }
      if (weapon === 2) {
        return jetThrowHeartLayers[index];
      }
    } else if (vehicle === 1) {
      if (weapon === 0) {
        return scootThrowTomatoLayers[index];
      }
      if (weapon === 1) {
        return scootThrowLemonLayers[index];
      }
      if (weapon === 2) {
        return scootThrowHeartLayers[index];
      }
    }
  };

  const handleThrow = async () => {
    var images = [];
    var mergeProgress = 0;

    setIsGenerating(true);
    let merged0 = await mergeImages([
      { src: bg0, x: 0, y: 0 },
      { src: imageScam, x: 144, y: 80 },
      { src: vehicle === 0 ? jetComing0 : scootComing0, x: 0, y: 0 },
    ]);
    images.push(merged0);
    setGeneratorProgress(mergeProgress + 0.01);
    mergeProgress += 0.01;

    // let merged1 = await mergeImages([
    //   { src: bg1, x: 0, y: 0 },
    //   { src: imageScam, x: 144, y: 80 },
    //   { src: vehicle === 0 ? jetComing1 : scootComing1, x: 0, y: 0 },
    // ]);
    // images.push(merged1);
    // setGeneratorProgress(mergeProgress + 0.01);
    // mergeProgress += 0.01;

    let merged2 = await mergeImages([
      { src: bg1, x: 0, y: 0 },
      { src: imageScam, x: 144, y: 80 },
      { src: vehicle === 0 ? jetComing2 : scootComing2, x: 0, y: 0 },
    ]);
    images.push(merged2);
    setGeneratorProgress(mergeProgress + 0.01);
    mergeProgress += 0.01;

    // let merged3 = await mergeImages([
    //   { src: bg3, x: 0, y: 0 },
    //   { src: imageScam, x: 144, y: 80 },
    //   { src: vehicle === 0 ? jetComing3 : scootComing3, x: 0, y: 0 },
    // ]);
    // images.push(merged3);
    // setGeneratorProgress(mergeProgress + 0.01);
    // mergeProgress += 0.01;

    let merged4 = await mergeImages([
      { src: bg2, x: 0, y: 0 },
      { src: imageScam, x: 144, y: 80 },
      { src: vehicle === 0 ? jetComing4 : scootComing4, x: 0, y: 0 },
    ]);
    images.push(merged4);
    setGeneratorProgress(mergeProgress + 0.01);
    mergeProgress += 0.01;

    // let merged5 = await mergeImages([
    //   { src: bg5, x: 0, y: 0 },
    //   { src: imageScam, x: 144, y: 80 },
    //   { src: vehicle === 0 ? jetComing5 : scootComing5, x: 0, y: 0 },
    // ]);
    // images.push(merged5);
    // setGeneratorProgress(mergeProgress + 0.01);
    // mergeProgress += 0.01;

    let merged6 = await mergeImages([
      { src: bg3, x: 0, y: 0 },
      { src: imageScam, x: 144, y: 80 },
      { src: vehicle === 0 ? jetComing6 : scootComing6, x: 0, y: 0 },
    ]);
    images.push(merged6);
    setGeneratorProgress(mergeProgress + 0.01);
    mergeProgress += 0.01;

    // let merged7 = await mergeImages([
    //   { src: bg6, x: 0, y: 0 },
    //   { src: imageScam, x: 144, y: 80 },
    //   { src: vehicle === 0 ? jetComing7 : scootComing7, x: 0, y: 0 },
    // ]);
    // images.push(merged7);
    // setGeneratorProgress(mergeProgress + 0.01);
    // mergeProgress += 0.01;

    let merged8 = await mergeImages([
      { src: bg4, x: 0, y: 0 },
      { src: imageScam, x: 144, y: 80 },
      { src: vehicle === 0 ? jetComing8 : scootComing8, x: 0, y: 0 },
    ]);
    images.push(merged8);
    setGeneratorProgress(mergeProgress + 0.01);
    mergeProgress += 0.01;

    // let merged9 = await mergeImages([
    //   { src: bg8, x: 0, y: 0 },
    //   { src: imageScam, x: 144, y: 80 },
    //   { src: vehicle === 0 ? jetComing9 : scootComing9, x: 0, y: 0 },
    // ]);
    // images.push(merged9);
    // setGeneratorProgress(mergeProgress + 0.01);
    // mergeProgress += 0.01;

    let merged10 = await mergeImages([
      { src: bg5, x: 0, y: 0 },
      { src: imageScam, x: 144, y: 80 },
      { src: vehicle === 0 ? jetComing10 : scootComing10, x: 0, y: 0 },
    ]);
    images.push(merged10);
    setGeneratorProgress(mergeProgress + 0.01);
    mergeProgress += 0.01;

    // let merged11 = await mergeImages([
    //   { src: bg10, x: 0, y: 0 },
    //   { src: imageScam, x: 144, y: 80 },
    //   { src: vehicle === 0 ? jetComing11 : scootComing11, x: 0, y: 0 },
    // ]);
    // images.push(merged11);
    // setGeneratorProgress(mergeProgress + 0.01);
    // mergeProgress += 0.01;

    let merged12 = await mergeImages([
      { src: bg6, x: 0, y: 0 },
      { src: imageScam, x: 144, y: 80 },
      { src: vehicle === 0 ? jetComing12 : scootComing12, x: 0, y: 0 },
    ]);
    images.push(merged12);
    setGeneratorProgress(mergeProgress + 0.01);
    mergeProgress += 0.01;

    // let merged13 = await mergeImages([
    //   { src: bg12, x: 0, y: 0 },
    //   { src: imageScam, x: 144, y: 80 },
    //   { src: vehicle === 0 ? jetComing13 : scootComing13, x: 0, y: 0 },
    // ]);
    // images.push(merged13);
    // setGeneratorProgress(mergeProgress + 0.01);
    // mergeProgress += 0.01;

    let merged14 = await mergeImages([
      { src: bg7, x: 0, y: 0 },
      { src: imageScam, x: 144, y: 80 },
      { src: vehicle === 0 ? jetComing14 : scootComing14, x: 0, y: 0 },
    ]);
    images.push(merged14);
    setGeneratorProgress(mergeProgress + 0.01);
    mergeProgress += 0.01;

    // let merged15 = await mergeImages([
    //   { src: bg14, x: 0, y: 0 },
    //   { src: imageScam, x: 144, y: 80 },
    //   { src: vehicle === 0 ? jetComing15 : scootComing15, x: 0, y: 0 },
    // ]);
    // images.push(merged15);
    // setGeneratorProgress(mergeProgress + 0.01);
    // mergeProgress += 0.01;

    let merged16 = await mergeImages([
      { src: bg8, x: 0, y: 0 },
      { src: imageScam, x: 144, y: 80 },
      { src: vehicle === 0 ? jetComing16 : scootComing16, x: 0, y: 0 },
    ]);
    images.push(merged16);
    setGeneratorProgress(mergeProgress + 0.01);
    mergeProgress += 0.01;

    // let merged17 = await mergeImages([
    //   { src: bg16, x: 0, y: 0 },
    //   { src: imageScam, x: 144, y: 80 },
    //   { src: vehicle === 0 ? jetComing17 : scootComing17, x: 0, y: 0 },
    // ]);
    // images.push(merged17);
    // setGeneratorProgress(mergeProgress + 0.01);
    // mergeProgress += 0.01;

    let merged18 = await mergeImages([
      { src: bg9, x: 0, y: 0 },
      { src: imageScam, x: 144, y: 80 },
      { src: vehicle === 0 ? jetComing18 : scootComing18, x: 0, y: 0 },
    ]);
    images.push(merged18);
    setGeneratorProgress(mergeProgress + 0.01);
    mergeProgress += 0.01;

    // let merged19 = await mergeImages([
    //   { src: bg18, x: 0, y: 0 },
    //   { src: imageScam, x: 144, y: 80 },
    //   { src: vehicle === 0 ? jetComing19 : scootComing19, x: 0, y: 0 },
    // ]);
    // images.push(merged19);
    // setGeneratorProgress(mergeProgress + 0.01);
    // mergeProgress += 0.01;

    let merged20 = await mergeImages([
      { src: bg10, x: 0, y: 0 },
      { src: imageScam, x: 144, y: 80 },
      { src: vehicle === 0 ? jetComing20 : scootComing20, x: 0, y: 0 },
    ]);
    images.push(merged20);
    setGeneratorProgress(mergeProgress + 0.01);
    mergeProgress += 0.01;

    // let merged21 = await mergeImages([
    //   { src: bg20, x: 0, y: 0 },
    //   { src: imageScam, x: 144, y: 80 },
    //   { src: vehicle === 0 ? jetComing21 : scootComing21, x: 0, y: 0 },
    // ]);
    // images.push(merged21);
    // setGeneratorProgress(mergeProgress + 0.01);
    // mergeProgress += 0.01;

    let merged22 = await mergeImages([
      { src: bg11, x: 0, y: 0 },
      { src: imageScam, x: 144, y: 80 },
      { src: vehicle === 0 ? jetComing22 : scootComing22, x: 0, y: 0 },
    ]);
    images.push(merged22);
    setGeneratorProgress(mergeProgress + 0.01);
    mergeProgress += 0.01;

    // let merged23 = await mergeImages([
    //   { src: bg22, x: 0, y: 0 },
    //   { src: imageScam, x: 144, y: 80 },
    //   { src: vehicle === 0 ? jetComing23 : scootComing23, x: 0, y: 0 },
    // ]);
    // images.push(merged23);
    // setGeneratorProgress(mergeProgress + 0.01);
    // mergeProgress += 0.01;

    let merged24 = await mergeImages([
      { src: bg12, x: 0, y: 0 },
      { src: imageScam, x: 144, y: 80 },
      { src: vehicle === 0 ? jetComing24 : scootComing24, x: 0, y: 0 },
    ]);
    images.push(merged24);
    setGeneratorProgress(mergeProgress + 0.01);
    mergeProgress += 0.01;

    // let merged25 = await mergeImages([
    //   { src: bg24, x: 0, y: 0 },
    //   { src: imageScam, x: 144, y: 80 },
    //   { src: vehicle === 0 ? jetComing25 : scootComing25, x: 0, y: 0 },
    // ]);
    // images.push(merged25);
    // setGeneratorProgress(mergeProgress + 0.01);
    // mergeProgress += 0.01;

    let merged26 = await mergeImages([
      { src: bg13, x: 0, y: 0 },
      { src: imageScam, x: 144, y: 80 },
      { src: vehicle === 0 ? jetComing26 : scootComing26, x: 0, y: 0 },
    ]);
    images.push(merged26);
    setGeneratorProgress(mergeProgress + 0.01);
    mergeProgress += 0.01;

    // let merged27 = await mergeImages([
    //   { src: bg26, x: 0, y: 0 },
    //   { src: imageScam, x: 144, y: 80 },
    //   { src: vehicle === 0 ? jetComing27 : scootComing27, x: 0, y: 0 },
    // ]);
    // images.push(merged27);
    // setGeneratorProgress(mergeProgress + 0.01);
    // mergeProgress += 0.01;

    let merged28 = await mergeImages([
      { src: bg14, x: 0, y: 0 },
      { src: imageScam, x: 144, y: 80 },
      { src: vehicle === 0 ? jetComing28 : scootComing28, x: 0, y: 0 },
    ]);
    images.push(merged28);
    setGeneratorProgress(mergeProgress + 0.01);
    mergeProgress += 0.01;

    // if (vehicle === 1) {
    //   let merged29 = await mergeImages([
    //     { src: bg28, x: 0, y: 0 },
    //     { src: imageScam, x: 144, y: 80 },
    //     { src: scootComing29, x: 0, y: 0 },
    //   ]);
    //   images.push(merged29);
    //   setGeneratorProgress(mergeProgress + 0.01);
    //   mergeProgress += 0.01;
    // }

    if (vehicle === 1) {
      let merged30 = await mergeImages([
        { src: bg15, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        { src: scootComing30, x: 0, y: 0 },
      ]);
      images.push(merged30);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    }

    // if (vehicle === 1) {
    //   let merged31 = await mergeImages([
    //     { src: bg30, x: 0, y: 0 },
    //     { src: imageScam, x: 144, y: 80 },
    //     { src: scootComing31, x: 0, y: 0 },
    //   ]);
    //   images.push(merged31);
    //   setGeneratorProgress(mergeProgress + 0.01);
    //   mergeProgress += 0.01;
    // }

    if (vehicle === 1) {
      let merged32 = await mergeImages([
        { src: bg16, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        { src: scootComing32, x: 0, y: 0 },
      ]);
      images.push(merged32);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    }

    // if (vehicle === 1) {
    //   let merged33 = await mergeImages([
    //     { src: bg32, x: 0, y: 0 },
    //     { src: imageScam, x: 144, y: 80 },
    //     { src: scootComing33, x: 0, y: 0 },
    //   ]);
    //   images.push(merged33);
    //   setGeneratorProgress(mergeProgress + 0.01);
    //   mergeProgress += 0.01;
    // }

    if (vehicle === 1) {
      let merged34 = await mergeImages([
        { src: bg17, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        { src: scootComing34, x: 0, y: 0 },
      ]);
      images.push(merged34);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    }

    // if (vehicle === 1) {
    //   let merged35 = await mergeImages([
    //     { src: bg34, x: 0, y: 0 },
    //     { src: imageScam, x: 144, y: 80 },
    //     { src: scootComing35, x: 0, y: 0 },
    //   ]);
    //   images.push(merged35);
    //   setGeneratorProgress(mergeProgress + 0.01);
    //   mergeProgress += 0.01;
    // }

    /**************************************************************
    / THROW 
    ***************************************************************/

    if (vehicle === 0) {
      let merged36 = await mergeImages([
        { src: bg18, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        { src: getThrowLayer(vehicle, weapon, 0), x: 0, y: 0 },
      ]);
      images.push(merged36);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    } else {
      let merged36 = await mergeImages([
        { src: bg18, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        {
          src: getThrowLayer(vehicle, weapon, 0),
          x: 0,
          y: 0,
        },
      ]);
      images.push(merged36);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    }

    if (vehicle === 0) {
      let merged37 = await mergeImages([
        { src: bg19, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        { src: getThrowLayer(vehicle, weapon, 1), x: 0, y: 0 },
      ]);
      images.push(merged37);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    } else {
      let merged37 = await mergeImages([
        { src: bg19, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        {
          src: getThrowLayer(vehicle, weapon, 1),
          x: 0,
          y: 0,
        },
      ]);
      images.push(merged37);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    }

    // if (vehicle === 0) {
    //   let merged38 = await mergeImages([
    //     { src: bg37, x: 0, y: 0 },
    //     { src: imageScam, x: 144, y: 80 },
    //     { src: getThrowLayer(vehicle, weapon, 2) , x: 0, y: 0 },
    //   ]);
    //   images.push(merged38);
    //   setGeneratorProgress(mergeProgress + 0.01);
    //   mergeProgress += 0.01;
    // } else {
    //   let merged38 = await mergeImages([
    //     { src: bg37, x: 0, y: 0 },
    //     { src: imageScam, x: 144, y: 80 },
    //     {
    //       src: getThrowLayer(vehicle, weapon, 2),
    //       x: 0,
    //       y: 0,
    //     },
    //   ]);
    //   images.push(merged38);
    //   setGeneratorProgress(mergeProgress + 0.01);
    //   mergeProgress += 0.01;
    // }

    if (vehicle === 0) {
      let merged39 = await mergeImages([
        { src: bg20, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        { src: getThrowLayer(vehicle, weapon, 3), x: 0, y: 0 },
      ]);
      images.push(merged39);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    } else {
      let merged39 = await mergeImages([
        { src: bg20, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        {
          src: getThrowLayer(vehicle, weapon, 3),
          x: 0,
          y: 0,
        },
      ]);
      images.push(merged39);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    }

    // if (vehicle === 0) {
    //   let merged40 = await mergeImages([
    //     { src: bg39, x: 0, y: 0 },
    //     { src: imageScam, x: 144, y: 80 },
    //     { src: getThrowLayer(vehicle, weapon, 4) , x: 0, y: 0 },
    //   ]);
    //   images.push(merged40);
    //   setGeneratorProgress(mergeProgress + 0.01);
    //   mergeProgress += 0.01;
    // } else {
    //   let merged40 = await mergeImages([
    //     { src: bg39, x: 0, y: 0 },
    //     { src: imageScam, x: 144, y: 80 },
    //     {
    //       src: getThrowLayer(vehicle, weapon, 4),
    //       x: 0,
    //       y: 0,
    //     },
    //   ]);
    //   images.push(merged40);
    //   setGeneratorProgress(mergeProgress + 0.01);
    //   mergeProgress += 0.01;
    // }

    if (vehicle === 0) {
      let merged41 = await mergeImages([
        { src: bg21, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        { src: getThrowLayer(vehicle, weapon, 5), x: 0, y: 0 },
      ]);
      images.push(merged41);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    } else {
      let merged41 = await mergeImages([
        { src: bg21, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        {
          src: getThrowLayer(vehicle, weapon, 5),
          x: 0,
          y: 0,
        },
      ]);
      images.push(merged41);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    }

    // if (vehicle === 0) {
    //   let merged42 = await mergeImages([
    //     { src: bg41, x: 0, y: 0 },
    //     { src: imageScam, x: 144, y: 80 },
    //     { src: getThrowLayer(vehicle, weapon, 6) , x: 0, y: 0 },
    //   ]);
    //   images.push(merged42);
    //   setGeneratorProgress(mergeProgress + 0.01);
    //   mergeProgress += 0.01;
    // } else {
    //   let merged42 = await mergeImages([
    //     { src: bg41, x: 0, y: 0 },
    //     { src: imageScam, x: 144, y: 80 },
    //     {
    //       src: getThrowLayer(vehicle, weapon, 6),
    //       x: 0,
    //       y: 0,
    //     },
    //   ]);
    //   images.push(merged42);
    //   setGeneratorProgress(mergeProgress + 0.01);
    //   mergeProgress += 0.01;
    // }

    if (vehicle === 0) {
      let merged43 = await mergeImages([
        { src: bg22, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        { src: getThrowLayer(vehicle, weapon, 7), x: 0, y: 0 },
      ]);
      images.push(merged43);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    } else {
      let merged43 = await mergeImages([
        { src: bg22, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        {
          src: getThrowLayer(vehicle, weapon, 7),
          x: 0,
          y: 0,
        },
      ]);
      images.push(merged43);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    }

    // if (vehicle === 0) {
    //   let merged44 = await mergeImages([
    //     { src: bg43, x: 0, y: 0 },
    //     { src: imageScam, x: 144, y: 80 },
    //     { src: getThrowLayer(vehicle, weapon, 8) , x: 0, y: 0 },
    //   ]);
    //   images.push(merged44);
    //   setGeneratorProgress(mergeProgress + 0.01);
    //   mergeProgress += 0.01;
    // } else {
    //   let merged44 = await mergeImages([
    //     { src: bg43, x: 0, y: 0 },
    //     { src: imageScam, x: 144, y: 80 },
    //     {
    //       src: getThrowLayer(vehicle, weapon, 8),
    //       x: 0,
    //       y: 0,
    //     },
    //   ]);
    //   images.push(merged44);
    //   setGeneratorProgress(mergeProgress + 0.01);
    //   mergeProgress += 0.01;
    // }

    if (vehicle === 0) {
      let merged45 = await mergeImages([
        { src: bg23, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        { src: getThrowLayer(vehicle, weapon, 9), x: 0, y: 0 },
      ]);
      images.push(merged45);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    } else {
      let merged45 = await mergeImages([
        { src: bg23, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        {
          src: getThrowLayer(vehicle, weapon, 9),
          x: 0,
          y: 0,
        },
      ]);
      images.push(merged45);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    }

    // if (vehicle === 0) {
    //   let merged46 = await mergeImages([
    //     { src: bg45, x: 0, y: 0 },
    //     { src: imageScam, x: 144, y: 80 },
    //     {
    //       src: getThrowLayer(vehicle, weapon, 10),
    //       x: 0,
    //       y: 0,
    //     },
    //   ]);
    //   images.push(merged46);
    //   setGeneratorProgress(mergeProgress + 0.01);
    //   mergeProgress += 0.01;
    // } else {
    //   let merged46 = await mergeImages([
    //     { src: bg45, x: 0, y: 0 },
    //     { src: imageScam, x: 144, y: 80 },
    //     {
    //       src: getThrowLayer(vehicle, weapon, 10),
    //       x: 0,
    //       y: 0,
    //     },
    //   ]);
    //   images.push(merged46);
    //   setGeneratorProgress(mergeProgress + 0.01);
    //   mergeProgress += 0.01;
    // }

    if (vehicle === 0) {
      let merged47 = await mergeImages([
        { src: bg24, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        {
          src: getThrowLayer(vehicle, weapon, 11),
          x: 0,
          y: 0,
        },
      ]);
      images.push(merged47);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    } else {
      let merged47 = await mergeImages([
        { src: bg24, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        {
          src: getThrowLayer(vehicle, weapon, 11),
          x: 0,
          y: 0,
        },
      ]);
      images.push(merged47);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    }

    if (vehicle === 0) {
      let merged48 = await mergeImages([
        { src: bg25, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        {
          src: getThrowLayer(vehicle, weapon, 12),
          x: 0,
          y: 0,
        },
      ]);
      images.push(merged48);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    } else {
      let merged48 = await mergeImages([
        { src: bg25, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        {
          src: getThrowLayer(vehicle, weapon, 12),
          x: 0,
          y: 0,
        },
      ]);
      images.push(merged48);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    }

    if (vehicle === 0) {
      let merged49 = await mergeImages([
        { src: bg26, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        {
          src: getThrowLayer(vehicle, weapon, 13),
          x: 0,
          y: 0,
        },
      ]);
      images.push(merged49);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    } else {
      let merged49 = await mergeImages([
        { src: bg26, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        {
          src: getThrowLayer(vehicle, weapon, 13),
          x: 0,
          y: 0,
        },
      ]);
      images.push(merged49);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    }

    if (vehicle === 0) {
      let merged49a = await mergeImages([
        { src: bg26, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        {
          src: getThrowLayer(vehicle, weapon, 14),
          x: 0,
          y: 0,
        },
      ]);
      images.push(merged49a);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    } else {
      let merged49a = await mergeImages([
        { src: bg26, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        {
          src: getThrowLayer(vehicle, weapon, 14),
          x: 0,
          y: 0,
        },
      ]);
      images.push(merged49a);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    }

    if (vehicle === 0) {
      let merged49b = await mergeImages([
        { src: bg26, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        {
          src: getThrowLayer(vehicle, weapon, 16),
          x: 0,
          y: 0,
        },
      ]);
      images.push(merged49b);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    } else {
      let merged49b = await mergeImages([
        { src: bg26, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        {
          src: getThrowLayer(vehicle, weapon, 16),
          x: 0,
          y: 0,
        },
      ]);
      images.push(merged49b);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    }

    /**************************************************************
    / LEAVING 
    ***************************************************************/

    // let merged50 = await mergeImages([
    //   { src: bg49, x: 0, y: 0 },
    //   { src: imageScam, x: 144, y: 80 },
    //   { src: getLeavingLayer(vehicle, weapon, 0), x: 0, y: 0 },
    // ]);
    // images.push(merged50);
    // setGeneratorProgress(mergeProgress + 0.01);
    // mergeProgress += 0.01;

    if (vehicle === 1) {
      let merged51 = await mergeImages([
        { src: weapon === 2 ? bgFireworks27 : bg27, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        { src: getLeavingLayer(vehicle, weapon, 1), x: 0, y: 0 },
      ]);
      images.push(merged51);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    }

    // let merged52 = await mergeImages([
    //   { src: bg51, x: 0, y: 0 },
    //   { src: imageScam, x: 144, y: 80 },
    //   { src: getLeavingLayer(vehicle, weapon, 2), x: 0, y: 0 },
    // ]);
    // images.push(merged52);
    // setGeneratorProgress(mergeProgress + 0.01);
    // mergeProgress += 0.01;

    if (vehicle === 1) {
      let merged53 = await mergeImages([
        { src: weapon === 2 ? bgFireworks28 : bg28, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        { src: getLeavingLayer(vehicle, weapon, 3), x: 0, y: 0 },
      ]);
      images.push(merged53);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    }

    // let merged54 = await mergeImages([
    //   { src: bg53, x: 0, y: 0 },
    //   { src: imageScam, x: 144, y: 80 },
    //   { src: getLeavingLayer(vehicle, weapon, 4), x: 0, y: 0 },
    // ]);
    // images.push(merged54);
    // setGeneratorProgress(mergeProgress + 0.01);
    // mergeProgress += 0.01;

    // let merged55 = await mergeImages([
    //   { src: bg54, x: 0, y: 0 },
    //   { src: imageScam, x: 144, y: 80 },
    //   { src: getLeavingLayer(vehicle, weapon, 5), x: 0, y: 0 },
    // ]);
    // images.push(merged55);
    // setGeneratorProgress(mergeProgress + 0.01);
    // mergeProgress += 0.01;

    let merged56 = await mergeImages([
      { src: weapon === 2 ? bgFireworks29 : bg29, x: 0, y: 0 },
      { src: imageScam, x: 144, y: 80 },
      { src: getLeavingLayer(vehicle, weapon, 6), x: 0, y: 0 },
    ]);
    images.push(merged56);
    setGeneratorProgress(mergeProgress + 0.01);
    mergeProgress += 0.01;

    // let merged57 = await mergeImages([
    //   { src: bg56, x: 0, y: 0 },
    //   { src: imageScam, x: 144, y: 80 },
    //   { src: getLeavingLayer(vehicle, weapon, 7), x: 0, y: 0 },
    // ]);
    // images.push(merged57);
    // setGeneratorProgress(mergeProgress + 0.01);
    // mergeProgress += 0.01;

    let merged58 = await mergeImages([
      { src: weapon === 2 ? bgFireworks30 : bg30, x: 0, y: 0 },
      { src: imageScam, x: 144, y: 80 },
      { src: getLeavingLayer(vehicle, weapon, 8), x: 0, y: 0 },
    ]);
    images.push(merged58);
    setGeneratorProgress(mergeProgress + 0.01);
    mergeProgress += 0.01;

    // let merged59 = await mergeImages([
    //   { src: bg58, x: 0, y: 0 },
    //   { src: imageScam, x: 144, y: 80 },
    //   { src: getLeavingLayer(vehicle, weapon, 9), x: 0, y: 0 },
    // ]);
    // images.push(merged59);
    // setGeneratorProgress(mergeProgress + 0.01);
    // mergeProgress += 0.01;

    let merged60 = await mergeImages([
      { src: weapon === 2 ? bgFireworks31 : bg31, x: 0, y: 0 },
      { src: imageScam, x: 144, y: 80 },
      { src: getLeavingLayer(vehicle, weapon, 10), x: 0, y: 0 },
    ]);
    images.push(merged60);
    setGeneratorProgress(mergeProgress + 0.01);
    mergeProgress += 0.01;

    // let merged61 = await mergeImages([
    //   { src: bg1, x: 0, y: 0 },
    //   { src: imageScam, x: 144, y: 80 },
    //   { src: getLeavingLayer(vehicle, weapon, 11), x: 0, y: 0 },
    // ]);
    // images.push(merged61);
    // setGeneratorProgress(mergeProgress + 0.01);
    // mergeProgress += 0.01;

    let merged62 = await mergeImages([
      { src: weapon === 2 ? bgFireworks32 : bg32, x: 0, y: 0 },
      { src: imageScam, x: 144, y: 80 },
      { src: getLeavingLayer(vehicle, weapon, 12), x: 0, y: 0 },
    ]);
    images.push(merged62);
    setGeneratorProgress(mergeProgress + 0.01);
    mergeProgress += 0.01;

    // let merged63 = await mergeImages([
    //   { src: bg1, x: 0, y: 0 },
    //   { src: imageScam, x: 144, y: 80 },
    //   { src: getLeavingLayer(vehicle, weapon, 13), x: 0, y: 0 },
    // ]);
    // images.push(merged63);
    // setGeneratorProgress(mergeProgress + 0.01);
    // mergeProgress += 0.01;

    let merged64 = await mergeImages([
      { src: weapon === 2 ? bgFireworks33 : bg33, x: 0, y: 0 },
      { src: imageScam, x: 144, y: 80 },
      { src: getLeavingLayer(vehicle, weapon, 14), x: 0, y: 0 },
    ]);
    images.push(merged64);
    setGeneratorProgress(mergeProgress + 0.01);
    mergeProgress += 0.01;

    // let merged65 = await mergeImages([
    //   { src: bg1, x: 0, y: 0 },
    //   { src: imageScam, x: 144, y: 80 },
    //   { src: getLeavingLayer(vehicle, weapon, 15), x: 0, y: 0 },
    // ]);
    // images.push(merged65);
    // setGeneratorProgress(mergeProgress + 0.01);
    // mergeProgress += 0.01;

    let merged66 = await mergeImages([
      { src: weapon === 2 ? bgFireworks34 : bg34, x: 0, y: 0 },
      { src: imageScam, x: 144, y: 80 },
      { src: getLeavingLayer(vehicle, weapon, 16), x: 0, y: 0 },
    ]);
    images.push(merged66);
    setGeneratorProgress(mergeProgress + 0.01);
    mergeProgress += 0.01;

    // let merged67 = await mergeImages([
    //   { src: bg1, x: 0, y: 0 },
    //   { src: imageScam, x: 144, y: 80 },
    //   { src: getLeavingLayer(vehicle, weapon, 17), x: 0, y: 0 },
    // ]);
    // images.push(merged67);
    // setGeneratorProgress(mergeProgress + 0.01);
    // mergeProgress += 0.01;

    let merged68 = await mergeImages([
      { src: weapon === 2 ? bgFireworks35 : bg35, x: 0, y: 0 },
      { src: imageScam, x: 144, y: 80 },
      { src: getLeavingLayer(vehicle, weapon, 18), x: 0, y: 0 },
    ]);
    images.push(merged68);
    setGeneratorProgress(mergeProgress + 0.01);
    mergeProgress += 0.01;

    // let merged69 = await mergeImages([
    //   { src: bg1, x: 0, y: 0 },
    //   { src: imageScam, x: 144, y: 80 },
    //   { src: getLeavingLayer(vehicle, weapon, 19), x: 0, y: 0 },
    // ]);
    // images.push(merged69);
    // setGeneratorProgress(mergeProgress + 0.01);
    // mergeProgress += 0.01;

    let merged70 = await mergeImages([
      { src: weapon === 2 ? bgFireworks36 : bg36, x: 0, y: 0 },
      { src: imageScam, x: 144, y: 80 },
      { src: getLeavingLayer(vehicle, weapon, 20), x: 0, y: 0 },
    ]);
    images.push(merged70);
    setGeneratorProgress(mergeProgress + 0.01);
    mergeProgress += 0.01;

    // let merged71 = await mergeImages([
    //   { src: bg1, x: 0, y: 0 },
    //   { src: imageScam, x: 144, y: 80 },
    //   { src: getLeavingLayer(vehicle, weapon, 21), x: 0, y: 0 },
    // ]);
    // images.push(merged71);
    // setGeneratorProgress(mergeProgress + 0.01);
    // mergeProgress += 0.01;

    let merged72 = await mergeImages([
      { src: weapon === 2 ? bgFireworks37 : bg37, x: 0, y: 0 },
      { src: imageScam, x: 144, y: 80 },
      { src: getLeavingLayer(vehicle, weapon, 22), x: 0, y: 0 },
    ]);
    images.push(merged72);
    setGeneratorProgress(mergeProgress + 0.01);
    mergeProgress += 0.01;

    // let merged73 = await mergeImages([
    //   { src: bg1, x: 0, y: 0 },
    //   { src: imageScam, x: 144, y: 80 },
    //   { src: getLeavingLayer(vehicle, weapon, 23), x: 0, y: 0 },
    // ]);
    // images.push(merged73);
    // setGeneratorProgress(mergeProgress + 0.01);
    // mergeProgress += 0.01;

    let merged74 = await mergeImages([
      { src: weapon === 2 ? bgFireworks38 : bg38, x: 0, y: 0 },
      { src: imageScam, x: 144, y: 80 },
      { src: getLeavingLayer(vehicle, weapon, 24), x: 0, y: 0 },
    ]);
    images.push(merged74);
    setGeneratorProgress(mergeProgress + 0.01);
    mergeProgress += 0.01;

    // let merged75 = await mergeImages([
    //   { src: bg1, x: 0, y: 0 },
    //   { src: imageScam, x: 144, y: 80 },
    //   { src: getLeavingLayer(vehicle, weapon, 25), x: 0, y: 0 },
    // ]);
    // images.push(merged75);
    // setGeneratorProgress(mergeProgress + 0.01);
    // mergeProgress += 0.01;

    let merged76 = await mergeImages([
      { src: weapon === 2 ? bgFireworks39 : bg39, x: 0, y: 0 },
      { src: imageScam, x: 144, y: 80 },
      { src: getLeavingLayer(vehicle, weapon, 26), x: 0, y: 0 },
    ]);
    images.push(merged76);
    setGeneratorProgress(mergeProgress + 0.01);
    mergeProgress += 0.01;

    // let merged77 = await mergeImages([
    //   { src: bg1, x: 0, y: 0 },
    //   { src: imageScam, x: 144, y: 80 },
    //   { src: getLeavingLayer(vehicle, weapon, 27), x: 0, y: 0 },
    // ]);
    // images.push(merged77);
    // setGeneratorProgress(mergeProgress + 0.01);
    // mergeProgress += 0.01;

    let merged78 = await mergeImages([
      { src: weapon === 2 ? bgFireworks40 : bg40, x: 0, y: 0 },
      { src: imageScam, x: 144, y: 80 },
      { src: getLeavingLayer(vehicle, weapon, 28), x: 0, y: 0 },
    ]);
    images.push(merged78);
    setGeneratorProgress(mergeProgress + 0.01);
    mergeProgress += 0.01;

    // if (vehicle === 1) {
    //   let merged79 = await mergeImages([
    //     { src: bg1, x: 0, y: 0 },
    //     { src: imageScam, x: 144, y: 80 },
    //     { src: scootLeaving29, x: 0, y: 0 },
    //   ]);
    //   images.push(merged79);
    //   setGeneratorProgress(mergeProgress + 0.01);
    //   mergeProgress += 0.01;
    // }

    if (vehicle === 1) {
      let merged80 = await mergeImages([
        { src: weapon === 2 ? bgFireworks41 : bg41, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        { src: getLeavingLayer(vehicle, weapon, 30), x: 0, y: 0 },
      ]);
      images.push(merged80);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    } else if (vehicle === 0 && weapon === 2) {
      let merged80 = await mergeImages([
        { src: bgFireworks41, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        { src: getLeavingLayer(vehicle, weapon, 28), x: 0, y: 0 },
      ]);
      images.push(merged80);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    }

    // if (vehicle === 1) {
    //   let merged81 = await mergeImages([
    //     { src: bg1, x: 0, y: 0 },
    //     { src: imageScam, x: 144, y: 80 },
    //     { src: getLeavingLayer(vehicle, weapon, 31), x: 0, y: 0 },
    //   ]);
    //   images.push(merged81);
    //   setGeneratorProgress(mergeProgress + 0.01);
    //   mergeProgress += 0.01;
    // }

    if (vehicle === 1) {
      let merged82 = await mergeImages([
        { src: weapon === 2 ? bgFireworks42 : bg42, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        { src: getLeavingLayer(vehicle, weapon, 32), x: 0, y: 0 },
      ]);
      images.push(merged82);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    } else if (vehicle === 0 && weapon === 2) {
      let merged82 = await mergeImages([
        { src: bgFireworks42, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        { src: getLeavingLayer(vehicle, weapon, 28), x: 0, y: 0 },
      ]);
      images.push(merged82);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    }

    // if (vehicle === 1) {
    //   let merged83 = await mergeImages([
    //     { src: bg1, x: 0, y: 0 },
    //     { src: imageScam, x: 144, y: 80 },
    //     { src: getLeavingLayer(vehicle, weapon, 33), x: 0, y: 0 },
    //   ]);
    //   images.push(merged83);
    //   setGeneratorProgress(mergeProgress + 0.01);
    //   mergeProgress += 0.01;
    // }

    if (vehicle === 1) {
      let merged84 = await mergeImages([
        { src: weapon === 2 ? bgFireworks43 : bg43, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        { src: getLeavingLayer(vehicle, weapon, 34), x: 0, y: 0 },
      ]);
      images.push(merged84);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    } else if (vehicle === 0 && weapon === 2) {
      let merged84 = await mergeImages([
        { src: bgFireworks43, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        { src: getLeavingLayer(vehicle, weapon, 28), x: 0, y: 0 },
      ]);
      images.push(merged84);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    }

    // if (vehicle === 1) {
    //   let merged85 = await mergeImages([
    //     { src: bg1, x: 0, y: 0 },
    //     { src: imageScam, x: 144, y: 80 },
    //     { src: getLeavingLayer(vehicle, weapon, 35), x: 0, y: 0 },
    //   ]);
    //   images.push(merged85);
    //   setGeneratorProgress(mergeProgress + 0.01);
    //   mergeProgress += 0.01;
    // }

    if (vehicle === 1) {
      let merged86 = await mergeImages([
        { src: weapon === 2 ? bgFireworks44 : bg44, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        { src: getLeavingLayer(vehicle, weapon, 36), x: 0, y: 0 },
      ]);
      images.push(merged86);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    } else if (vehicle === 0 && weapon === 2) {
      let merged86 = await mergeImages([
        { src: bgFireworks44, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        { src: getLeavingLayer(vehicle, weapon, 28), x: 0, y: 0 },
      ]);
      images.push(merged86);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    }

    // if (vehicle === 1) {
    //   let merged87 = await mergeImages([
    //     { src: bg1, x: 0, y: 0 },
    //     { src: imageScam, x: 144, y: 80 },
    //     { src: getLeavingLayer(vehicle, weapon, 37), x: 0, y: 0 },
    //   ]);
    //   images.push(merged87);
    //   setGeneratorProgress(mergeProgress + 0.01);
    //   mergeProgress += 0.01;
    // }

    if (vehicle === 1) {
      let merged88 = await mergeImages([
        { src: weapon === 2 ? bgFireworks45 : bg45, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        { src: getLeavingLayer(vehicle, weapon, 38), x: 0, y: 0 },
      ]);
      images.push(merged88);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    } else if (vehicle === 0 && weapon === 2) {
      let merged88 = await mergeImages([
        { src: bgFireworks45, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        { src: getLeavingLayer(vehicle, weapon, 28), x: 0, y: 0 },
      ]);
      images.push(merged88);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    }

    // if (vehicle === 1) {
    //   let merged89 = await mergeImages([
    //     { src: bg1, x: 0, y: 0 },
    //     { src: imageScam, x: 144, y: 80 },
    //     { src: getLeavingLayer(vehicle, weapon, 39), x: 0, y: 0 },
    //   ]);
    //   images.push(merged89);
    //   setGeneratorProgress(mergeProgress + 0.01);
    //   mergeProgress += 0.01;
    // }

    if (vehicle === 1) {
      let merged90 = await mergeImages([
        { src: weapon === 2 ? bgFireworks46 : bg46, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        { src: getLeavingLayer(vehicle, weapon, 40), x: 0, y: 0 },
      ]);
      images.push(merged90);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    } else if (vehicle === 0 && weapon === 2) {
      let merged90 = await mergeImages([
        { src: bgFireworks46, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        { src: getLeavingLayer(vehicle, weapon, 28), x: 0, y: 0 },
      ]);
      images.push(merged90);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    }

    // if (vehicle === 1) {
    //   let merged91 = await mergeImages([
    //     { src: bg1, x: 0, y: 0 },
    //     { src: imageScam, x: 144, y: 80 },
    //     { src: getLeavingLayer(vehicle, weapon, 41), x: 0, y: 0 },
    //   ]);
    //   images.push(merged91);
    //   setGeneratorProgress(mergeProgress + 0.01);
    //   mergeProgress += 0.01;
    // }

    if (vehicle === 1) {
      let merged92 = await mergeImages([
        { src: weapon === 2 ? bgFireworks47 : bg47, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        { src: getLeavingLayer(vehicle, weapon, 42), x: 0, y: 0 },
      ]);
      images.push(merged92);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    } else if (vehicle === 0 && weapon === 2) {
      let merged92 = await mergeImages([
        { src: bgFireworks47, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        { src: getLeavingLayer(vehicle, weapon, 28), x: 0, y: 0 },
      ]);
      images.push(merged92);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    }

    // if (vehicle === 1) {
    //   let merged93 = await mergeImages([
    //     { src: bg1, x: 0, y: 0 },
    //     { src: imageScam, x: 144, y: 80 },
    //     { src: getLeavingLayer(vehicle, weapon, 43), x: 0, y: 0 },
    //   ]);
    //   images.push(merged93);
    //   setGeneratorProgress(mergeProgress + 0.01);
    //   mergeProgress += 0.01;
    // }

    if (vehicle === 1) {
      let merged94 = await mergeImages([
        { src: weapon === 2 ? bgFireworks48 : bg48, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        { src: getLeavingLayer(vehicle, weapon, 44), x: 0, y: 0 },
      ]);
      images.push(merged94);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    } else if (vehicle === 0 && weapon === 2) {
      let merged94 = await mergeImages([
        { src: bgFireworks48, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        { src: getLeavingLayer(vehicle, weapon, 28), x: 0, y: 0 },
      ]);
      images.push(merged94);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    }

    // if (vehicle === 1) {
    //   let merged95 = await mergeImages([
    //     { src: bg1, x: 0, y: 0 },
    //     { src: imageScam, x: 144, y: 80 },
    //     { src: getLeavingLayer(vehicle, weapon, 45), x: 0, y: 0 },
    //   ]);
    //   images.push(merged95);
    //   setGeneratorProgress(mergeProgress + 0.01);
    //   mergeProgress += 0.01;
    // }

    if (vehicle === 1) {
      let merged96 = await mergeImages([
        { src: weapon === 2 ? bgFireworks49 : bg49, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        { src: getLeavingLayer(vehicle, weapon, 46), x: 0, y: 0 },
      ]);
      images.push(merged96);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    } else if (vehicle === 0 && weapon === 2) {
      let merged96 = await mergeImages([
        { src: bgFireworks49, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        { src: getLeavingLayer(vehicle, weapon, 28), x: 0, y: 0 },
      ]);
      images.push(merged96);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    }

    // if (vehicle === 1) {
    //   let merged97 = await mergeImages([
    //     { src: bg1, x: 0, y: 0 },
    //     { src: imageScam, x: 144, y: 80 },
    //     { src: getLeavingLayer(vehicle, weapon, 47), x: 0, y: 0 },
    //   ]);
    //   images.push(merged97);
    //   setGeneratorProgress(mergeProgress + 0.01);
    //   mergeProgress += 0.01;
    // }

    if (vehicle === 1) {
      let merged98 = await mergeImages([
        { src: weapon === 2 ? bgFireworks50 : bg50, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        { src: getLeavingLayer(vehicle, weapon, 48), x: 0, y: 0 },
      ]);
      images.push(merged98);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    } else if (vehicle === 0 && weapon === 2) {
      let merged98 = await mergeImages([
        { src: bgFireworks50, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        { src: getLeavingLayer(vehicle, weapon, 28), x: 0, y: 0 },
      ]);
      images.push(merged98);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    }

    // if (vehicle === 1) {
    //   let merged99 = await mergeImages([
    //     { src: bg1, x: 0, y: 0 },
    //     { src: imageScam, x: 144, y: 80 },
    //     { src: getLeavingLayer(vehicle, weapon, 49), x: 0, y: 0 },
    //   ]);
    //   images.push(merged99);
    //   setGeneratorProgress(mergeProgress + 0.01);
    //   mergeProgress += 0.01;
    // }

    if (vehicle === 1) {
      let merged100 = await mergeImages([
        { src: weapon === 2 ? bgFireworks51 : bg51, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        { src: getLeavingLayer(vehicle, weapon, 50), x: 0, y: 0 },
      ]);
      images.push(merged100);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    } else if (vehicle === 0 && weapon === 2) {
      let merged100 = await mergeImages([
        { src: bgFireworks51, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        { src: getLeavingLayer(vehicle, weapon, 28), x: 0, y: 0 },
      ]);
      images.push(merged100);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    }

    // if (vehicle === 1) {
    //   let merged101 = await mergeImages([
    //     { src: bg1, x: 0, y: 0 },
    //     { src: imageScam, x: 144, y: 80 },
    //     { src: getLeavingLayer(vehicle, weapon, 51), x: 0, y: 0 },
    //   ]);
    //   images.push(merged101);
    //   setGeneratorProgress(mergeProgress + 0.01);
    //   mergeProgress += 0.01;
    // }

    if (vehicle === 1) {
      let merged102 = await mergeImages([
        { src: weapon === 2 ? bgFireworks52 : bg52, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        { src: getLeavingLayer(vehicle, weapon, 52), x: 0, y: 0 },
      ]);
      images.push(merged102);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    } else if (vehicle === 0 && weapon === 2) {
      let merged102 = await mergeImages([
        { src: bgFireworks52, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        { src: getLeavingLayer(vehicle, weapon, 28), x: 0, y: 0 },
      ]);
      images.push(merged102);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    }

    // if (vehicle === 1) {
    //   let merged103 = await mergeImages([
    //     { src: bg1, x: 0, y: 0 },
    //     { src: imageScam, x: 144, y: 80 },
    //     { src: getLeavingLayer(vehicle, weapon, 53), x: 0, y: 0 },
    //   ]);
    //   images.push(merged103);
    //   setGeneratorProgress(mergeProgress + 0.01);
    //   mergeProgress += 0.01;
    // }

    if (vehicle === 1) {
      let merged104 = await mergeImages([
        { src: weapon === 2 ? bgFireworks53 : bg53, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        { src: getLeavingLayer(vehicle, weapon, 54), x: 0, y: 0 },
      ]);
      images.push(merged104);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    } else if (vehicle === 0 && weapon === 2) {
      let merged104 = await mergeImages([
        { src: bgFireworks53, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        { src: getLeavingLayer(vehicle, weapon, 28), x: 0, y: 0 },
      ]);
      images.push(merged104);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    }

    // if (vehicle === 1) {
    //   let merged105 = await mergeImages([
    //     { src: bg1, x: 0, y: 0 },
    //     { src: imageScam, x: 144, y: 80 },
    //     { src: getLeavingLayer(vehicle, weapon, 55), x: 0, y: 0 },
    //   ]);
    //   images.push(merged105);
    //   setGeneratorProgress(mergeProgress + 0.01);
    //   mergeProgress += 0.01;
    // }

    if (vehicle === 1) {
      let merged106 = await mergeImages([
        { src: weapon === 2 ? bgFireworks54 : bg54, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        { src: getLeavingLayer(vehicle, weapon, 56), x: 0, y: 0 },
      ]);
      images.push(merged106);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    } else if (vehicle === 0 && weapon === 2) {
      let merged106 = await mergeImages([
        { src: bgFireworks54, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        { src: getLeavingLayer(vehicle, weapon, 28), x: 0, y: 0 },
      ]);
      images.push(merged106);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    }

    // if (vehicle === 1) {
    //   let merged107 = await mergeImages([
    //     { src: bg1, x: 0, y: 0 },
    //     { src: imageScam, x: 144, y: 80 },
    //     { src: getLeavingLayer(vehicle, weapon, 57), x: 0, y: 0 },
    //   ]);
    //   images.push(merged107);
    //   setGeneratorProgress(mergeProgress + 0.01);
    //   mergeProgress += 0.01;
    // }

    if (vehicle === 1) {
      let merged108 = await mergeImages([
        { src: weapon === 2 ? bgFireworks55 : bg55, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        { src: getLeavingLayer(vehicle, weapon, 58), x: 0, y: 0 },
      ]);
      images.push(merged108);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    } else if (vehicle === 0 && weapon === 2) {
      let merged108 = await mergeImages([
        { src: bgFireworks55, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
        { src: getLeavingLayer(vehicle, weapon, 28), x: 0, y: 0 },
      ]);
      images.push(merged108);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    }

    if (vehicle === 1 && weapon === 2) {
      let merged109 = await mergeImages([
        { src: bgFireworks56, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
      ]);
      images.push(merged109);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    }

    if (vehicle === 1 && weapon === 2) {
      let merged110 = await mergeImages([
        { src: bgFireworks57, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
      ]);
      images.push(merged110);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    }

    if (vehicle === 1 && weapon === 2) {
      let merged111 = await mergeImages([
        { src: bgFireworks58, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
      ]);
      images.push(merged111);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    }

    if (vehicle === 1 && weapon === 2) {
      let merged112 = await mergeImages([
        { src: bgFireworks59, x: 0, y: 0 },
        { src: imageScam, x: 144, y: 80 },
      ]);
      images.push(merged112);
      setGeneratorProgress(mergeProgress + 0.01);
      mergeProgress += 0.01;
    }

    // if (vehicle === 1) {
    //   let merged109 = await mergeImages([
    //     { src: bg1, x: 0, y: 0 },
    //     { src: imageScam, x: 144, y: 80 },
    //     { src: getLeavingLayer(vehicle, weapon, 59), x: 0, y: 0 },
    //   ]);
    //   images.push(merged109);
    //   setGeneratorProgress(mergeProgress + 0.01);
    //   mergeProgress += 0.01;
    // }

    window.gifshot.createGIF(
      {
        images: images,
        gifWidth: 512,
        gifHeight: 512,
        interval: 0.08,
        sampleInterval: 10,
        progressCallback: (progress) => {
          setGeneratorProgress(mergeProgress + progress * (1 - mergeProgress));
        },
      },
      function (obj) {
        logEvent(analytics, "generated_gif");

        setIsGenerating(false);
        if (!obj.error) {
          var image = obj.image;
          setScamGif(image);
        }
      }
    );
  };

  return (
    <Wrapper>
      <StepContainer>
        <Title>MVPD User Manual</Title>
        <SubTitle>Follow these steps for a quick start with the MVPD</SubTitle> 
        <Stepper>
          <StepLabel isActive={currentStep >= 0}>Step 1</StepLabel>
          <StepLabel isActive={currentStep >= 0}>&gt;</StepLabel>
          <StepLabel isActive={currentStep >= 1}>Step 2</StepLabel>
          <StepLabel isActive={currentStep >= 1}>&gt;</StepLabel>
          <StepLabel isActive={currentStep >= 2}>Step 3</StepLabel>
          <StepLabel isActive={currentStep >= 2}>&gt;</StepLabel>
          <StepLabel isActive={currentStep >= 3}>Step 4</StepLabel>
        </Stepper>
        {currentStep === 0 && (
          <StepWrapper>
            <StepTitleContainer>
              <StepTitle>Choose your vehicle</StepTitle>
            </StepTitleContainer>
            <ChooseVehicle
              vehicle={vehicle}
              onVehicleClicked={onVehicleClicked}
            />
          </StepWrapper>
        )}
        {currentStep === 1 && (
          <StepWrapper>
            <StepTitleContainer>
              <StepTitle>Choose your weapon</StepTitle>
              <BackButton onClick={() => setCurrentStep(currentStep - 1)}>
                {"<"} Back
              </BackButton>
            </StepTitleContainer>
            <ChooseWeapon weapon={weapon} onWeaponClicked={onWeaponClicked} />
          </StepWrapper>
        )}
        {currentStep === 2 && (
          <StepWrapper>
            <StepTitleContainer>
              <StepTitle>Choose target</StepTitle>
              <BackButton onClick={() => setCurrentStep(currentStep - 1)}>
                {"<"} Back
              </BackButton>
            </StepTitleContainer>
            <ChooseTarget
              onScamSelected={onScamChoose}
              isTargetScam={weapon !== 2}
            />
          </StepWrapper>
        )}
        {currentStep === 3 && (
          <StepWrapper>
            <StepTitleContainer>
              <StepTitle>Generate GIF</StepTitle>
              <BackButton
                onClick={() => {
                  setCurrentStep(currentStep - 1);
                  setScamGif(undefined);
                  setGeneratorProgress(0);
                }}
              >
                {"<"} Back
              </BackButton>
            </StepTitleContainer>
            {(scamGif || isGenerating) && (
              <GeneratorWrapper>
                {scamGif && <ScamGiff src={scamGif} alt="" />}
                {isGenerating && (
                  <>
                    <GeneratingText>Generating GIF...</GeneratingText>
                    <CustomProgressBar
                      bgcolor="#6a1b9a"
                      completed={Math.round(generatorProgress * 100)}
                    />
                  </>
                )}
              </GeneratorWrapper>
            )}
            {scamGif === undefined && !isGenerating && (
              <GenerateButton onClick={handleThrow} disabled={isGenerating}>
                Generate GIF
              </GenerateButton>
            )}

            {scamGif && (
              <DownloadButton download="MVPD.gif" href={scamGif}>
                Download GIF
              </DownloadButton>
            )}
          </StepWrapper>
        )}
      </StepContainer>
    </Wrapper>
  );
};

export default UserManual;
