import styled from "styled-components";
import Jet from "../../../JetKoala.png";
import Scoot from "../../../ScooterKoala.png";

const ChoiceWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin: 50px 0;
`;

const Choice = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(#e740cb, #ee7bb4);
  align-items: center;
  padding: 20px;
  border-radius: 15px;
  max-width: 150px;
  height: 200px;
  width: 25%;

  font-size: 18px;
  font-weight: 800;
  border: 3px solid white;
  cursor: pointer;
  opacity: 0.7;

  :hover {
    opacity: 1;
  }

  transition: 0.1s ease;
`;

const Text = styled.div`
  font-size: 18px;
  font-weight: 800;
  font-family: "Minecraft";
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const ImageWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Image = styled.img`
  width: 60%;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

const ChooseVehicle = ({ vehicle, onVehicleClicked }) => {
  return (
    <ChoiceWrapper>
      <Choice onClick={() => onVehicleClicked(0)}>
        <Text>Jet Pack</Text>
        <ImageWrapper>
          <Image src={Jet} />
        </ImageWrapper>
      </Choice>
      <Choice onClick={() => onVehicleClicked(1)}>
        <Text>Scooter</Text>
        <ImageWrapper>
          <Image src={Scoot} />
        </ImageWrapper>
      </Choice>
    </ChoiceWrapper>
  );
};

export default ChooseVehicle;
